import React from "react";
import { responsiveFontSizes } from "@mui/material";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
} from "react-router-dom";
import { AnimatedSwitch } from 'react-router-transition';


import { ThemeConfig, Colors, SiteConfig } from "./theme";
import { HomePage } from "./Pages/Home";
import { ProjectPage } from "./Pages/Project";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { TeamPage } from "./Pages/Team";

let theme = createTheme(ThemeConfig);
theme = responsiveFontSizes(theme);

const classes = {
  root: {
    backgroundColor: Colors.WHITE,
  },
};

function App() {
  return (
    <div
      style={{
        ...classes.root,
        overflowX: "hidden"
      }}
      className="App"
    >
      <HelmetProvider>
        <Helmet>
            <meta charSet={SiteConfig.charSet} />
            <title>{SiteConfig.title}</title>
            <meta name="description" content={SiteConfig.description} />
            <meta property="og:title" content={SiteConfig.title} />
            <meta property="og:image" content={`${SiteConfig.domainUrl}/images/logo-thumb.png`} />
            <meta property="og:type" content="article" />
            <meta property="og:description" content={SiteConfig.description} />
            <meta property="og:url" content={SiteConfig.domainUrl} />
        </Helmet>
        <ThemeProvider theme={theme}>
          <Router>
            <RouteList />
          </Router>
        </ThemeProvider>
      </HelmetProvider>
    </div>
  );
}

function RouteList() {
  return (
    <AnimatedSwitch
      atEnter={{ opacity: 0 }}
      atLeave={{ opacity: 0 }}
      atActive={{ opacity: 1 }}
      runOnMount={true}
      className="switch-wrapper"
    >
      <Route exact path="/" children={<HomePage />} />
      <Route exact path="/team" children={<TeamPage />} />
      <Route exact path="/project/banana" children={<ProjectPage slug="banana" />} />
      <Route exact path="/project/hitech-center" children={<ProjectPage slug="hitech-center" />} />
      <Route exact path="/project/love-sex-and-real-estate" children={<ProjectPage slug="love-sex-and-real-estate" />} />
      <Route exact path="/project/pingpong-6-asap" children={<ProjectPage slug="pingpong-6-asap" />} />
      <Route exact path="/project/an-apartment" children={<ProjectPage slug="an-apartment" />} />
      <Route exact path="/project/beyong-dewlling-album" children={<ProjectPage slug="beyong-dewlling-album" />} />
      <Route exact path="/project/drama-memorial" children={<ProjectPage slug="drama-memorial" />} />
      <Route exact path="/project/beyong-dwelling-the-interview" children={<ProjectPage slug="beyong-dwelling-the-interview" />} />
      <Route exact path="/project/a-horsey-story" children={<ProjectPage slug="a-horsey-story" />} />
      <Route exact path="/project/first-plan" children={<ProjectPage slug="first-plan" />} />
      <Route exact path="/project/second-plan" children={<ProjectPage slug="second-plan" />} />
      <Route exact path="/project/third-plan" children={<ProjectPage slug="third-plan" />} />
      <Route exact path="/project/ba-505" children={<ProjectPage slug="ba-505" />} />
      <Route exact path="/project/space-travellers" children={<ProjectPage slug="space-travellers" />} />
      <Route exact path="/project/miscellaneous" children={<ProjectPage slug="miscellaneous" />} />
      <Route exact path="/project/parish-house" children={<ProjectPage slug="parish-house" />} />
      <Route exact path="/project/longhouse" children={<ProjectPage slug="longhouse" />} />
      <Route exact path="/project/garden-house" children={<ProjectPage slug="garden-house" />} />
      <Route exact path="/project/hermitage" children={<ProjectPage slug="hermitage" />} />
      <Route exact path="/project/storage-hall" children={<ProjectPage slug="storage-hall" />} />
      <Route exact path="/project/seaside-house" children={<ProjectPage slug="seaside-house" />} />
      <Route exact path="/project/paperclip" children={<ProjectPage slug="paperclip" />} />
      <Route exact path="/project/ping-pong-6" children={<ProjectPage slug="ping-pong-6" />} />
      <Route exact path="/project/loooop" children={<ProjectPage slug="loooop" />} />
      <Route exact path="/project/enescu-house" children={<ProjectPage slug="enescu-house" />} />
      <Redirect to="/" />
    </AnimatedSwitch>
  )
}

export default App;
