import React from "react";
import { Box, Grid, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import { ProjectData } from "../data";
import { Slide, Zoom } from "react-awesome-reveal";
import { Link, useLocation } from "react-router-dom";

export type SlideDirection = "down" | "left" | "right" | "up";
export interface ProjectProps {
  data: ProjectData
  direction: SlideDirection
}

const getStyles = (theme: Theme) => ({
  projectContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  projectDetails: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    minHeight: '300px',
    [theme.breakpoints.down('sm')]: {
      minHeight: '250px'
    }
  },
  // Use this as a cover to trigger the hover effect
  projectHoverCover: { 
    position: 'absolute',
    width: '100%',
    height: '100%'
  },
  projectTitle: {
    textAlign: 'center',
    textDecoration: 'none'
  },
});

export const Project: React.FunctionComponent<ProjectProps> = ({
  data,
  direction,
}) => {
  const innerRef = React.useRef(null);
  const [isHovering, setIsHovering] = React.useState(false);
  const theme = useTheme();
  const location = useLocation()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const classes = getStyles(theme);

  const handleMouseEnter = () => {  
    if (!isHovering) setIsHovering(true)
  };

  const handleMouseLeave = () => {
    if (isHovering) setIsHovering(false)
  };
  

  // Effect used for adding a mouse event handler to increase the size of the cursor
  React.useEffect(() => {
    const element = innerRef.current;
    if (!element) {
      return;
    }
    function onMouseHover() {
      // @ts-ignore
      TweenMax.to($circle, .3, {
        scale: 4
      })
    }
    function onMouseHoverOut() {
      // @ts-ignore
      TweenMax.to($circle, .3, {
        scale: 1
      })
    }
    // @ts-ignore
    element.addEventListener('mouseenter', onMouseHover);
    // @ts-ignore
    element.addEventListener('mouseleave', onMouseHoverOut);

    return () => {
      onMouseHoverOut()
      // @ts-ignore
      element.removeEventListener('mouseenter', onMouseHover);
      // @ts-ignore
      element.removeEventListener('mouseleave', onMouseHoverOut);
    };
  }, []);
  const ProjectIcon = data.image
  return (
    <Grid item xs={12} sm={6} md={6} lg={4} sx={classes.projectContainer}>
      <Slide direction={direction}>
      <Link to={{
          pathname: `/project/${data.slug}`,
          state: { prevPath: location.pathname }
        }} 
        style={{ textDecoration: 'none' }}
        >
        <Box ref={innerRef}
          className="hoverable" 
          sx={classes.projectDetails}
        >
          <ProjectIcon fill={theme.palette.primary.contrastText} width={isMobile ? 250 : 300}/>
          {(isHovering || isMobile) && (
            <Zoom cascade={true} delay={isMobile ? 500 : 200}>
              <Typography sx={classes.projectTitle} my={2}>{data.title}</Typography>
            </Zoom>
          )}
          {!isMobile && <Box
            onMouseEnter={handleMouseEnter} 
            onMouseLeave={handleMouseLeave}
            sx={classes.projectHoverCover}
           ></Box>}
        </Box>
        </Link>
      </Slide>
    </Grid>
  );
};
