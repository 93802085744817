import React from "react";
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Carousel.css'
import { Colors } from '../../theme'


interface ArrowProps {
  [key: string]: unknown,
  onClick?: () => void
  
}
const GalleryPrevArrow: React.FunctionComponent<ArrowProps> = ({ currentSlide, slideCount, ...props }) => {
  const { onClick } = props;

  return (
    <div {...props} className="custom-prevArrow" onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        fill={Colors.WHITE}
      >
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
      </svg>
    </div>
  );
};
const GalleryNextArrow: React.FunctionComponent<ArrowProps> = ({ currentSlide, slideCount, ...props }) => {
  const { onClick } = props;

  return (
    <div {...props} className="custom-nextArrow" onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        fill={Colors.WHITE}
      >
        <path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z" />
      </svg>
    </div>
  );
};

interface CarouselProps {
    images: string[]
}

// Kinda messy but it is what it is
export const Carousel: React.FunctionComponent<CarouselProps> = ({
    images
}) => {
    const settings = {
        dots: true,
        infinite: true,
        slidesToScroll: 1,
        slidesToShow: 1,
        adaptiveHeight: true,
        className: 'center',
        centerMode: true,
        nextArrow: <GalleryNextArrow />,
        prevArrow: <GalleryPrevArrow />
    };


    const slides = images.map((image, index) => (
      <img key={`carousel-${index}`} src={`/images/${image}`} />
    ))

    return (
    <Slider {...settings}>
        {slides}
    </Slider>
    );
}
