import React from 'react';

import { Footer } from '../Components/Footer';
import { Header } from '../Components/Header';
import { ProjectList } from '../Components/ProjectList';
import { Section } from '../Components/Section';
import data from '../data';

export const HomePage: React.FunctionComponent<{}> = () => {
    return (
        <>
            <Section>
            <Header
                sections={data.sections}
            />
            </Section>
            {data.sections.map((section) => (
            <Section key={section.id} id={section.id} title={section.name}>
                <ProjectList data={data.projects[section.id]} />
            </Section>
            ))}
            <Section id="footer" useEmptyDivider={true}>
            <Footer />
            </Section>
        </>
    )
}
