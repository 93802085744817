import React from 'react';

import { Box, Grid, Link, Theme, Typography, useTheme } from '@mui/material';

import { SiteConfig } from '../theme';

interface FooterProps {}

const getStyles = (theme: Theme) => ({
  root: {
    position: 'relative',
    paddingBottom: 0,
    justifyContent: "space-around",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      minHeight: "50vh",
    },
  },
  footerContent: {
    alignItems: 'center',
    paddingTop: '100px',
    minHeight: "100vh",
    backgroundImage: 'url("/logo-footer.svg")',
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    [theme.breakpoints.down('md')]: {
      paddingTop: 0
    }
  },
  footerLink: {
    textDecoration: 'none'
  },
});

export const Footer: React.FunctionComponent<FooterProps> = () => {
  const theme = useTheme();
  const classes = getStyles(theme);
  const innerRef = React.useRef(null);
  const innerRef2 = React.useRef(null);
  const innerRef3 = React.useRef(null);
  // Workaround for hydration issue caused by react-snap
  // where the page would display as mobile on web on the first load.
  const [hasMounted, setHasMounted] = React.useState(false);
  React.useEffect(() => {
    setHasMounted(true);
  }, []);
  
  // Effect used for adding a mouse event handler to increase the size of the cursor
  React.useEffect(() => {
    const element = innerRef.current;
    const element2 = innerRef2.current;
    const element3 = innerRef3.current;
    if (!element || !element2 || !element3) {
      return;
    }
    function onMouseHover() {
      // @ts-ignore
      TweenMax.to($circle, .3, {
        scale: 2
      })
    }
    function onMouseHoverOut() {
      // @ts-ignore
      TweenMax.to($circle, .3, {
        scale: 1
      })
    }
    // @ts-ignore
    element.addEventListener('mouseenter', onMouseHover);
    // @ts-ignore
    element2.addEventListener('mouseenter', onMouseHover);
    // @ts-ignore
    element3.addEventListener('mouseenter', onMouseHover);
    // @ts-ignore
    element.addEventListener('mouseleave', onMouseHoverOut);
    // @ts-ignore
    element2.addEventListener('mouseleave', onMouseHoverOut);
    // @ts-ignore
    element3.addEventListener('mouseleave', onMouseHoverOut);

    return () => {
      onMouseHoverOut()
      // @ts-ignore
      element.removeEventListener('mouseenter', onMouseHover);
      // @ts-ignore
      element2.removeEventListener('mouseenter', onMouseHover);
      // @ts-ignore
      element3.removeEventListener('mouseenter', onMouseHover);
      // @ts-ignore
      element.removeEventListener('mouseleave', onMouseHoverOut);
      // @ts-ignore
      element2.removeEventListener('mouseleave', onMouseHoverOut);
      // @ts-ignore
      element3.removeEventListener('mouseleave', onMouseHoverOut);
    };
  }, []);

  if (!hasMounted) {
    return null;
  }

  return (
      <Grid container sx={classes.root}>
        <Grid container sx={classes.footerContent}>
          <Grid item lg={6} xs={12}>
            <Typography variant="h2">Just say hello...</Typography>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Typography variant="h4">hello@</Typography>
            <Typography variant="h4">punctoranj.ro</Typography>
            <Typography variant="h4">{SiteConfig.contactNumber}</Typography>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Typography variant="h4">Follow Us</Typography>
          </Grid>
          <Grid item lg={6} xs={12} sx={{
            display: 'flex',
            justifyContent: 'center'
          }}>
            <Box sx={{ textAlign: 'left', width: 'fit-content' }}>
              <Link sx={classes.footerLink} target="_blanc" href="https://www.instagram.com/punctoranj/">
                <Typography ref={innerRef} variant="h4">Instagram</Typography>
              </Link>
              <Link sx={classes.footerLink} target="_blanc" href="https://www.facebook.com/didier.chifan">
                <Typography ref={innerRef2} variant="h4">Facebook</Typography>
              </Link>
              <Link sx={classes.footerLink} target="_blanc" href="https://www.behance.net/profile/didierchifan">
                <Typography ref={innerRef3} variant="h4">Behance</Typography>
              </Link>
            </Box>
          </Grid>
        </Grid>
        <Grid sx={{ position: 'absolute', bottom: 0, right: 0, margin: '15px' }}>
            <Typography>{SiteConfig.copyright}</Typography>
        </Grid>
      </Grid>
  );
};
