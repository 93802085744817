export enum ComponentType {
    IMAGE_FULL = 'image-full',
    IMAGE_LEFT_RIGHT = 'image-left-right-margin',
    NARROW_IMAGE = 'narrow-image',
    NARROW_TEXT = 'narrow-text',
    NARROW_TEXT_IMAGE_RIGHT = 'narrow-text-image-right',
    NARROW_TEXT_IMAGE_LEFT = 'narrow-text-image-left',
    IMAGE_CAROUSEL = 'image-carousel'
}
type ComponentImageFull = {
    type: ComponentType.IMAGE_FULL
    image: string
    imageMobile: string
    link?: string
    margin?: boolean
    mixBlendMode?: 'normal' | 'multiply'
}

type ComponentImageLeftRightMargin = {
    type: ComponentType.IMAGE_LEFT_RIGHT
    imageLeft: string
    imageRight: string
    margin?: boolean
    mixBlendMode?: 'normal' | 'multiply'
}

type ComponentNarrowImage = {
    type: ComponentType.NARROW_IMAGE,
    image: string
    margin?: boolean
    mixBlendMode?: 'normal' | 'multiply'
}

type ComponentNarrowText = {
    type: ComponentType.NARROW_TEXT,
    text: string[]
    title?: string
    margin?: boolean
    mixBlendMode?: 'normal' | 'multiply'
    textAlign?: 'left' | 'right' | 'justify'
}

type ComponentNarrowTextImageRight = {
    type: ComponentType.NARROW_TEXT_IMAGE_RIGHT
    text: string[]
    image: string
    margin?: boolean
    mixBlendMode?: 'normal' | 'multiply'
    textAlign?: 'left' | 'right' | 'justify'
}

type ComponentNarrowTextImageLeft = {
    type: ComponentType.NARROW_TEXT_IMAGE_LEFT
    text: string[]
    image: string
    margin?: boolean
    mixBlendMode?: 'normal' | 'multiply'
    textAlign?: 'left' | 'right' | 'justify'
}

type ComponentImageCarousel = {
    type: ComponentType.IMAGE_CAROUSEL
    images: string[]
    margin?: boolean
    mixBlendMode?: 'normal' | 'multiply'
}

export type ProjectComponent = ComponentImageFull |
    ComponentImageLeftRightMargin |
    ComponentNarrowText |
    ComponentNarrowTextImageRight |
    ComponentNarrowTextImageLeft |
    ComponentNarrowImage |
    ComponentImageCarousel

export interface ProjectDetails {
    metadata: {
        title: string,
        description: string,
        image: string
    }
    components: ProjectComponent[]
}
export const projectDetails: {
    [slug: string]: ProjectDetails
} = {
    'banana': {
        metadata: {
            title: 'LET ME EXPLAIN',
            description: 'let me explain now… you’ll understand later',
            image: '01.banana/let-me-explain-thumb.jpg'
        },
        components: [{
            type: ComponentType.IMAGE_FULL,
            image: '01.banana/01.banana.jpg',
            imageMobile: '01.banana/01.banana-mobile.jpg'
        },{
            type: ComponentType.NARROW_TEXT,
            text: [`Inspired by the Memphis Group, our proposal aims to welcome customers and collaborators into a creative, cheerful and nonconformist space.
            Our desire is to transform the classic, cold and strictly functional lobby of Gara Herastrau into a space that will bring smile on your lips.Keeping the simplicity of Superstudio geometric lines, combining a palette of colors from pop art, going along with abstract patterns, this “marriage” of retro styles (Pop Art x Art Deco x Bauhaus) imposes, in a challenging and harmonious way, an aesthetic, not only postmodern, but contemporary. Rethinking the interior design, we bring to life the ground floor of the building, bringing attention from the outside world and breaking the limits of the conventional. The lobby is no longer just a lobby, but an art gallery with many cultural references and emotional awakenings.`]
        },{
            type: ComponentType.IMAGE_FULL,
            image: '01.banana/02.banana.jpg',
            imageMobile: '01.banana/02.banana.jpg',
        },{
            type: ComponentType.IMAGE_FULL,
            image: '01.banana/03.banana.jpg',
            imageMobile: '01.banana/03.banana.jpg',
            margin: true
        },{
            type: ComponentType.IMAGE_FULL,
            image: '01.banana/04.banana.jpg',
            imageMobile: '01.banana/04.banana.jpg',
        },{
            type: ComponentType.IMAGE_FULL,
            image: '01.banana/05.banana.jpg',
            imageMobile: '01.banana/05.banana.jpg',
            margin: true
        },{
            type: ComponentType.IMAGE_FULL,
            image: '01.banana/06.banana.jpg',
            imageMobile: '01.banana/06.banana.jpg',
        },{
            type: ComponentType.IMAGE_FULL,
            image: '01.banana/07.banana.jpg',
            imageMobile: '01.banana/07.banana.jpg',
            margin: true
        },{
            type: ComponentType.IMAGE_LEFT_RIGHT,
            imageLeft: '01.banana/08.banana.jpg',
            imageRight: '01.banana/09.banana.jpg',
        },{
            type: ComponentType.IMAGE_FULL,
            image: '01.banana/10.banana.gif',
            imageMobile: '01.banana/10.banana.gif',
            margin: true
        }]
    },
    'hitech-center': {
        metadata: {
            title: 'UAUIM HIGH TECH-CENTER',
            description: 'the most high-tech classroom you’ll find in school',
            image: '02.hitech-center/uauim-high-tech-center-thumb.jpg'
        },
        components: [{
            type: ComponentType.IMAGE_FULL,
            image: '02.hitech-center/01.hitech.jpg',
            imageMobile: '02.hitech-center/02.hitech-mobile.png'
        },{
            type: ComponentType.NARROW_TEXT_IMAGE_RIGHT,
            image: '02.hitech-center/02.hitech.jpg',
            text: [
                `“The Globalworth Foundation inaugurates the first Hi-Tech Centre for future architects, within the “Ion Mincu” University of Architecture and Urbanism, Bucharest. Through this modernization and endowment project, with a value of up to 240,000 euros, the foundation aims to bring the prestigious university to a competitive European level from all perspectives: research, design and education.The solution developed at the Hi-Tech Learning Centre provides a wide range of functions and is based on a mix of technologies, so as to meet the specific needs of the University:
            A modular space renovated and equipped with a complete VR solution, which helps students build and communicate their proposals. The first virtual production studio will provide the university with a new virtual environment that students can use without being limited by the design of physical space. A videoconferencing system that will allow teachers and students to communicate in real-time with other academics, in Romania and abroad.
            3D printers for the manufacture of prototypes and models, used by students to test their projects. Ergonomic and mobile workstations equipped with 22 computers and high-performance software. A solution for data capturing – photography, 3D scanning and photogrammetry. Thanks to the new laboratory, every year, over 3,500 future architects will have at their disposal modern design technologies and technologies that can change the field of architecture and the environment in which we live and work.”`,
                `text from: https://www.globalworth.com'`
            ],
            margin: true
        },{
            type: ComponentType.IMAGE_FULL,
            image: '02.hitech-center/03.hitech.jpg',
            imageMobile: '02.hitech-center/03.hitech.jpg'
        },{
            type: ComponentType.IMAGE_FULL,
            image: '02.hitech-center/04.hitech.jpg',
            imageMobile: '02.hitech-center/04.hitech.jpg',
            margin: true
        },{
            type: ComponentType.IMAGE_FULL,
            image: '02.hitech-center/05.hitech.jpg',
            imageMobile: '02.hitech-center/05.hitech.jpg'
        },{
            type: ComponentType.IMAGE_FULL,
            image: '02.hitech-center/06.hitech.jpg',
            imageMobile: '02.hitech-center/06.hitech.jpg',
            margin: true
        },{
            type: ComponentType.IMAGE_FULL,
            image: '02.hitech-center/07.hitech.jpg',
            imageMobile: '02.hitech-center/07.hitech.jpg'
        },{
            type: ComponentType.IMAGE_FULL,
            image: '02.hitech-center/08.hitech.jpg',
            imageMobile: '02.hitech-center/08.hitech.jpg',
            margin: true
        },{
            type: ComponentType.IMAGE_FULL,
            image: '02.hitech-center/09.hitech.jpg',
            imageMobile: '02.hitech-center/09.hitech.jpg'
        },{
            type: ComponentType.IMAGE_FULL,
            image: '02.hitech-center/10.hitech.jpg',
            imageMobile: '02.hitech-center/10.hitech.jpg',
            margin: true
        },{
            type: ComponentType.IMAGE_FULL,
            image: '02.hitech-center/11.hitech.jpg',
            imageMobile: '02.hitech-center/11.hitech.jpg'
        },{
            type: ComponentType.IMAGE_FULL,
            image: '02.hitech-center/12.hitech.jpg',
            imageMobile: '02.hitech-center/12.hitech.jpg',
            margin: true
        },{
            type: ComponentType.IMAGE_FULL,
            image: '02.hitech-center/13.hitech.jpg',
            imageMobile: '02.hitech-center/13.hitech.jpg'
        },{
            type: ComponentType.IMAGE_FULL,
            image: '02.hitech-center/14.hitech.jpg',
            imageMobile: '02.hitech-center/14.hitech.jpg',
            margin: true
        },{
            type: ComponentType.IMAGE_FULL,
            image: '02.hitech-center/15.hitech.jpg',
            imageMobile: '02.hitech-center/15.hitech.jpg'
        },{
            type: ComponentType.IMAGE_FULL,
            image: '02.hitech-center/16.hitech.jpg',
            imageMobile: '02.hitech-center/16.hitech.jpg',
            margin: true
        },{
            type: ComponentType.IMAGE_FULL,
            image: '02.hitech-center/17.hitech.jpg',
            imageMobile: '02.hitech-center/17.hitech.jpg'
        }]
    },
    'love-sex-and-real-estate': {
        metadata: {
            title: 'LOVE SEX AND REAL ESTATE',
            description: 'the title says it all…',
            image: '03.love-sex-and-real-estate/love-sex-and-real-estate-thumb.jpg'
        },
        components: [{
            type: ComponentType.IMAGE_FULL,
            image: '03.love-sex-and-real-estate/001.love-sex-and-real-estate.gif',
            imageMobile: '03.love-sex-and-real-estate/03.love-sex-real-estate-mobile.gif',
        }, {
            type: ComponentType.NARROW_TEXT,
            title: 'LOVE SEX AND REAL ESTATE',
            text: [`“May you work be compelling and original. May it be profound, touching, contemplative, and unique. May it help us to reflect on the question of what it means to be human, and may the reflection be blessed with hearth, sincerity, candor, and grace. May you overcome adversity, censorship, poverty and nihilism, as many of you will most certainly be obliged to do. 
            May you be blessed with the talent and rigor to teach us about the beating of the human heart in all its complexity, and the humility and curiosity to make it your life’s work. And may the best of you - for it will only be the best of you, and even then only in the rarest and briefest moments - succeed in framing that most basic of questions, “how do we live” Godspeed”`,
            `-John Malkovich-`]
        },{
            type: ComponentType.IMAGE_CAROUSEL,
            images: [
                '03.love-sex-and-real-estate/1.jpg',
                '03.love-sex-and-real-estate/2.jpg',
                '03.love-sex-and-real-estate/3.jpg',
                '03.love-sex-and-real-estate/4.jpg',
                '03.love-sex-and-real-estate/5.jpg',
                '03.love-sex-and-real-estate/6.jpg',
                '03.love-sex-and-real-estate/7.jpg',
                '03.love-sex-and-real-estate/8.jpg',
                '03.love-sex-and-real-estate/9.jpg',
                '03.love-sex-and-real-estate/10.jpg',
                '03.love-sex-and-real-estate/11.jpg',
                '03.love-sex-and-real-estate/12.jpg',
                '03.love-sex-and-real-estate/13.jpg',
                '03.love-sex-and-real-estate/14.jpg',
                '03.love-sex-and-real-estate/15.jpg',
                '03.love-sex-and-real-estate/16.jpg',
                '03.love-sex-and-real-estate/17.jpg',
                '03.love-sex-and-real-estate/18.jpg',
                '03.love-sex-and-real-estate/19.jpg',
                '03.love-sex-and-real-estate/20.jpg',
            ]
        }]
    },
    'pingpong-6-asap': {
        metadata: {
            title: 'PINGPONG+6',
            description: 'a 2022 Electric Castle hit',
            image: '04.pp6/pingpong+6-thumb.jpg'
        },
        components: [{
            type: ComponentType.IMAGE_FULL,
            image: '04.pp6/01.asap.jpg',
            imageMobile: '04.pp6/04.pingpong-x-asap-mobile.jpg'
        },{
            type: ComponentType.IMAGE_FULL,
            image: '04.pp6/02.asap.jpg',
            imageMobile: '04.pp6/02.asap.jpg',
        },{
            type: ComponentType.IMAGE_FULL,
            image: '04.pp6/03.asap.jpg',
            imageMobile: '04.pp6/03.asap.jpg',
        },{
            type: ComponentType.IMAGE_FULL,
            image: '04.pp6/04.asap.jpg',
            imageMobile: '04.pp6/04.asap.jpg',
            margin: true
        },{
            type: ComponentType.IMAGE_LEFT_RIGHT,
            imageLeft: '04.pp6/05.asap.jpg',
            imageRight: '04.pp6/06.asap.jpg',
            margin: true
        },{
            type: ComponentType.IMAGE_LEFT_RIGHT,
            imageLeft: '04.pp6/07.asap.jpg',
            imageRight: '04.pp6/08.asap.jpg',
            margin: true
        },{
            type: ComponentType.NARROW_IMAGE,
            image: '04.pp6/09.asap.jpg',
            margin: true
        },{
            type: ComponentType.IMAGE_LEFT_RIGHT,
            imageLeft: '04.pp6/10.asap.jpg',
            imageRight: '04.pp6/11.asap.jpg',
        }]
    },
    'an-apartment': {
        metadata: {
            title: 'GHEORGHIENI 34',
            description: 'an apartment for friends',
            image: '05.gheorghieni34/gheorghieni-34-thumb.jpg'
        },
        components: [{
            type: ComponentType.IMAGE_FULL,
            image: '05.gheorghieni34/01.coperta.jpg',
            imageMobile: '05.gheorghieni34/01.coperta-mobile.jpg'
        },{
            type: ComponentType.IMAGE_CAROUSEL,
            images: [
                '05.gheorghieni34/1.apartament.jpg',
                '05.gheorghieni34/2.apartament.jpg',
                '05.gheorghieni34/3.apartament.jpg',
                '05.gheorghieni34/4.apartament.jpg',
                '05.gheorghieni34/5.apartament.jpg',
                '05.gheorghieni34/6.apartament.jpg',
                '05.gheorghieni34/7.apartament.jpg'
            ],
            margin: true
        }]
    },
    'beyong-dewlling-album': {
        metadata: {
            title: 'BEYOND DWELLING',
            description: 'hint: it’s all about drawing',
            image: '06.beyond-dwelling/beyond-dwelling-thumb.jpg'
        },
        components: [{
            type: ComponentType.IMAGE_FULL,
            image: '06.beyond-dwelling/01.coperta.png',
            imageMobile: '06.beyond-dwelling/01.coperta-mobile.png',
            link: 'https://www.peecho.com/checkout/158014661297241097/1044272/'
        },{
            type: ComponentType.IMAGE_CAROUSEL,
            images: [
                '06.beyond-dwelling/carusel1.jpg',
                '06.beyond-dwelling/carusel2.jpg',
                '06.beyond-dwelling/carusel3.jpg',
                '06.beyond-dwelling/carusel4.jpg',
                '06.beyond-dwelling/carusel5.jpg',
                '06.beyond-dwelling/carusel6.jpg',
                '06.beyond-dwelling/carusel7.jpg',
                '06.beyond-dwelling/carusel8.jpg',
                '06.beyond-dwelling/carusel9.jpg',
                '06.beyond-dwelling/carusel10.jpg',
                '06.beyond-dwelling/carusel11.jpg',
                '06.beyond-dwelling/carusel12.jpg',
                '06.beyond-dwelling/carusel13.jpg',
                '06.beyond-dwelling/carusel14.jpg',
                '06.beyond-dwelling/carusel15.jpg',
                '06.beyond-dwelling/carusel16.jpg',
                '06.beyond-dwelling/carusel17.jpg',
                '06.beyond-dwelling/carusel18.jpg',
                '06.beyond-dwelling/carusel19.jpg',
                '06.beyond-dwelling/carusel20.jpg',
                '06.beyond-dwelling/carusel21.jpg',
                '06.beyond-dwelling/carusel22.jpg',
                '06.beyond-dwelling/carusel23.jpg',
                '06.beyond-dwelling/carusel24.jpg',
                '06.beyond-dwelling/carusel25.jpg',
                '06.beyond-dwelling/carusel26.jpg',
                '06.beyond-dwelling/carusel27.jpg',
                '06.beyond-dwelling/carusel28.jpg',
                '06.beyond-dwelling/carusel29.jpg',
                '06.beyond-dwelling/carusel30.jpg',
                '06.beyond-dwelling/carusel31.jpg',
                '06.beyond-dwelling/carusel32.jpg',
            ]
        }]
    },
    'drama-memorial': {
        metadata: {
            title: 'DRAMA MEMORIAL',
            description: `there’s a church under water`,
            image: '07.drama-memorial/drama-memorial-thumb.jpg'
        },
        components: [{
            type: ComponentType.IMAGE_FULL,
            image: '07.drama-memorial/01.drama.jpg',
            imageMobile: '07.drama-memorial/07.drama-memorial-mobile.jpg'
        },{
            type: ComponentType.NARROW_TEXT_IMAGE_RIGHT,
            image: '07.drama-memorial/02.drama.jpg',
            text: [
                `În spatele oricărei intervenții arhitecturale există sau ar trebui să existe un demers bazat pe căutări care au ca scop înțelegerea profundă a contextului; pentru că acesta există sau ar trebui să existe indiferent de voința arhitectului. Natura prezentului este determinată întotdeauna de un “ieri”, adică de un trecut spre care privim ca să putem găsi soluții la problemele lansate de căutările în care ne angrenăm spre a da cel mai bun răspuns arhitectural posibil. Printr-un semn de întrebare este căutată existența punctului, dar de cele mai multe ori aceasta întârzie să apară prin interpunerea mai multor alte semne de întrebare. Când apare această incertitudine, intuiția este cea prin care se ajunge la un răspuns (arhitectural); acesta din urmă vine ca formă materială a punctului și va putea fi întotdeauna contestat, întrucât poartă marca subiectivității interpretării. Acest fapt oferă inevitabil răspunsului caracterul de posibilitate și nu de certitudine. Contextul existent influențează așadar arhitectura și va fi la rândul lui influențat de către aceasta în viitorul care o cuprinde. Există locuri ce riscă să devină un “nicăieri” prin uitare sau chiar dispariție. Satul Geamăna este unul dintre ele, iar prin proiectul de diplomă îi este descoperit un potențial viitor, dacă nu al lui, cel puțin al factorului care îl mai ține în viață – memoria.`,
                `Pornit pe calea intuiției, proiectul de diplomă va salva măcar o fărâmă din Geamăna, dacă nu prin realizarea concretă, măcar prin intenție și ridicarea unor probleme de natură morală. Traseul merge, de fapt, dincolo de proiect și capătă o semnificație metafizică; el reprezintă calea descoperirii unui sens profund al arhitecturii, mai departe de funcțiune și formă pentru arhitectul la început de drum.`
            ],
            margin: true
        },{
            type: ComponentType.IMAGE_FULL,
            image: '07.drama-memorial/03.drama.jpg',
            imageMobile: '07.drama-memorial/03.drama.jpg',
        },{
            type: ComponentType.NARROW_TEXT_IMAGE_LEFT,
            image: '07.drama-memorial/04.drama.jpg',
            text: [
                `Bisericuței de la iaz și satului cu mult necaz

                “Putere, acuma, de-aș avea,
                De-aici, acuma, v-aș muta
                Și-aș clădi lăcașuri noi,
                Ca să vă strămut pe voi.

                Să strămut ce se mai poate
                Să nu moară-n apă toate,
                Clopotele, sfinte icoane,
                Doamne, Ajută-ne Doamne!

                Iar voi bisericuțe sfinte,
                Ce mai rămâne nemutat?
                Rămâneți singure ca un soldat,
                În apă, de pază la morminte!

                Noi, ce vii, am îndurat,
                Am mai rămas și-am mai plecat,
                Dar voi, voi cei din morminte,
                Voi nu vă puteți de-aici a vă desprinde.

                Și au rămas aici, sub apă,
                Sortiți să mai moară o dată.
                Iartă-ne, Doamne, pe toți
                Că am ajuns să înecăm morți.”

                Geamăna, mai 2004`
            ],
            margin: true
        },{
            type: ComponentType.IMAGE_FULL,
            image: '07.drama-memorial/05.drama.jpg',
            imageMobile: '07.drama-memorial/05.drama.jpg',
        },{
            type: ComponentType.IMAGE_FULL,
            image: '07.drama-memorial/06.drama.jpg',
            imageMobile: '07.drama-memorial/06.drama.jpg',
            margin: true
        },{
            type: ComponentType.IMAGE_FULL,
            image: '07.drama-memorial/07.drama.jpg',
            imageMobile: '07.drama-memorial/07.drama.jpg',
        },{
            type: ComponentType.IMAGE_FULL,
            image: '07.drama-memorial/08.drama-blend.jpg',
            imageMobile: '07.drama-memorial/08.drama-blend.jpg',
            mixBlendMode: 'multiply',
            margin: true
        },{
            type: ComponentType.IMAGE_FULL,
            image: '07.drama-memorial/09.drama.jpg',
            imageMobile: '07.drama-memorial/09.drama.jpg',
        },{
            type: ComponentType.IMAGE_FULL,
            image: '07.drama-memorial/10.drama.jpg',
            imageMobile: '07.drama-memorial/10.drama.jpg',
            margin: true
        },{
            type: ComponentType.IMAGE_FULL,
            image: '07.drama-memorial/10.drama-blend.jpg',
            imageMobile: '07.drama-memorial/10.drama-blend.jpg',
            mixBlendMode: 'multiply'
        },{
            type: ComponentType.IMAGE_FULL,
            image: '07.drama-memorial/11.drama.jpg',
            imageMobile: '07.drama-memorial/11.drama.jpg',
            margin: true
        },{
            type: ComponentType.IMAGE_FULL,
            image: '07.drama-memorial/12.drama-blend.jpg',
            imageMobile: '07.drama-memorial/12.drama-blend.jpg',
            mixBlendMode: 'multiply'
        },{
            type: ComponentType.IMAGE_FULL,
            image: '07.drama-memorial/13.drama-blend.jpg',
            imageMobile: '07.drama-memorial/13.drama-blend.jpg',
            mixBlendMode: 'multiply',
            margin: true
        },{
            type: ComponentType.IMAGE_FULL,
            image: '07.drama-memorial/14.drama.jpg',
            imageMobile: '07.drama-memorial/14.drama.jpg',
        },{
            type: ComponentType.IMAGE_FULL,
            image: '07.drama-memorial/15.drama.jpg',
            imageMobile: '07.drama-memorial/15.drama.jpg',
            margin: true
        },{
            type: ComponentType.IMAGE_FULL,
            image: '07.drama-memorial/16.drama.jpg',
            imageMobile: '07.drama-memorial/16.drama.jpg',
        }]
    },
    'beyong-dwelling-the-interview': {
        metadata: {
            title: 'BEYOND DWELLING - THE INTERVIEW',
            description: 'koozarch asked my some questions and I answered',
            image: '08.bd-the-interview/beyond-dwelling-interview-thumb.jpg'
        },
        components: [{
            type: ComponentType.IMAGE_FULL,
            image: '08.bd-the-interview/01.BD.jpg',
            imageMobile: '08.bd-the-interview/08.BD-mobile.jpg'
        },{
            type: ComponentType.NARROW_TEXT,
            text: [
                `“What may motivate us to make long-term commitments? Maybe there was only a constraint coming from the society and the always growing freedom has helped us detach ourselves and discern it from a different perspective. Or maybe there is a need in the essence of every person, a need to dig oneself in something/somewhere – a need for a stable affiliation both rooted in a certain place or in certain people who become “ours”.

                We all live in a time of quickly consuming things. We tend to be free from any oppression that interfere with our freedom. It is way easier to replace what is not working, instead of finding a smart solution for that specific problem and even if we start looking for it, we often quit fast. There is a tumult of life that makes us have no more respite, so a path that involves attempts in many directions is preferred rather than a single path assumed by a determined decision. Modern human lifestyle emphasizes on a diversity of experiences and not on a monotony of steadiness.
                
                That being said, what could be an architectural response in relation to the life of contemporary human beings? Reducing the search area to one of the necessities of life – dwelling – with the help of this project I brought into discussion certain terms and concepts that potentiate a way to follow. The dwelling program is analyzed using tools to investigate the validity of an answer, discussed on the basis of a criteria considered relevant for understanding the issue. A balance is being sought in the current tendencies of the consumerism person between its positive aspects and those that could harm in the long run.
                The aims of the project are identifying and solving an existing, extremely visible problem, represented by a derelict area, situated on the eastern boundary of the old city center of Ljubljana. The adaptive reuse of the former sugar factory involves primarily the integration of the living / co-living function, together with other functions for the general public interest, revitalizing the place and redefining its relationship with the old city center. In addition to this, the project describes different ways of living, the varied communities representing the primary element that guides the entire design process. The representation of the project, inspired by the comic book illustrations, complements the architectural solution.”`
            ]
        },{
            type: ComponentType.NARROW_TEXT,
            text: [
                `Interview

                What prompted the project?
                
                The main idea for the project was born whilst researching for my master degree thesis that is also entitled “Beyond Dwelling”. I have always been interested in the notion of dwelling and I felt that this was going to be an interesting area of research for my diploma project.
                
                I was particularly intrigued by new ways of living and the constantly changing living requirements of new generations, especially the concept of co-living. One important tool that helped me outline the main ideas of the project was a survey made by IKEA and SPACE 10. Other reference projects include the Mini co-housing project – Shanghai, China, Cluster House – Zurich as well as The Collective – London.
                
                All of this was then brought back to a site that I had previously studied, an abandoned old sugar factory. This felt like the right place to design a sustainable and different type of dwelling, through adaptive reuse.`
            ]
        },{
            type: ComponentType.IMAGE_FULL,
            image: '08.bd-the-interview/02.BD.jpg',
            imageMobile: '08.bd-the-interview/02.BD.jpg'
        },{
            type: ComponentType.NARROW_TEXT,
            text: [
                `What drew you to the site of Ljubljana and the former sugar factory?

                I had been living in Ljubljana for half a year and I had studied the sugar factory site during a small university project. It had a specific outline within the particular landscape of Ljubljana, a dominant structure which defined a very unique atmosphere for its surroundings.
                
                In light of the state of abandonment of the building and its location as one which delineates the  the end of the old city center, an architectural intervention was required. It seemed that the building had an immense potential in activating the surroundings and creating a strong connection with the old city center.
                
                The project wants to address and reflect upon ideas as the implementation of a distinct concept of dwelling and the experimentation with different types of communities. While providing an adequate layout for apartments in relation to its structural spans, the building required a sustainable intervention through adaptive reuse.`
            ]
        },{
            type: ComponentType.NARROW_IMAGE,
            image: '08.bd-the-interview/03.BD.jpg',
        },{
            type: ComponentType.NARROW_TEXT,
            text: [
                `What tools did you use to investigate and analyze the site?

                At first, I visited the site, took notes and made sketches. Then, I took pictures and analyzed them by comparing with older ones. When revisiting the place, I tried to capture the atmosphere as it was and think how it could evolve. Moreover, I had the opportunity to discuss with colleagues and teachers who live in Ljubljana and they provided me with the original drawings of the building from different periods of time. Another important tool was the site model which helped me understand the structure of the city, the circulation, the landmarks, the city’s profile and the significance of the Ljubljanica River.`
            ]
        },{
            type: ComponentType.IMAGE_FULL,
            image: '08.bd-the-interview/04.BD.jpg',
            imageMobile: '08.bd-the-interview/04.BD.jpg',
            mixBlendMode: "multiply"
        },{
            type: ComponentType.NARROW_TEXT,
            text: [
                `How important was the site model?

                The site model was of utmost importance. Even if I had sufficient data with regards to the site and surroundings, I felt the need of a bigger picture which included the city’s profile, the river, the hills with the castle. All these elements played an important role in the development and articulation of the project. I used the site model every time I needed to asses how my project would work at an urban scale, the important relationship between the site and the city center, the circulation. The new opportunities for linking the project site with the city center were carefully observed with the aid of the site models.`
            ]
        },{
            type: ComponentType.IMAGE_FULL,
            image: '08.bd-the-interview/05.BD.jpg',
            imageMobile: '08.bd-the-interview/05.BD.jpg',
        },{
            type: ComponentType.NARROW_TEXT,
            text: [
                `How does the structure relate within the greater context of the city?

                The aim of the project was to reestablish the link between the old city center and its limit by proposing different functions and integrating them in the existing structure.
                
                Preserving and reinforcing the original structure of the sugar factory was an important step in the design process which suggests the polyvalence of a structure and how it can contemporarily speak of the past and the future. As a result, the spaces situated on the ground floor (an art gallery, a green park, a skate park, a restaurant, a place for children daycare and a few piers on the river) hold public activities meant to reactivate both the building and its surroundings, making this area an integral part of the city center, not only a fading limit.
                
                Another important aspect was the connection of the site with other parts of the city, especially the residential areas. As a result, the structure integrates a number of ready to rent offices and workshop spaces.
                
                A striking aspect of the building is that it was split up in two parts by Fabiani Bridge, an intervention well known in Ljubljana. The purpose of this bridge was to close the main city ring and facilitate the peripheral circulation. Therefore, the bridge is part of the project, having an important impact at visual, aesthetic and functional level.`
            ]
        },{
            type: ComponentType.NARROW_IMAGE,
            image: '08.bd-the-interview/06.BD.jpg',
        }, {
            type: ComponentType.NARROW_TEXT,
            text: [
                `Could you define in greater detail the programmatic distribution of the structure? How do living and co-living merge?

                Even if living and co-living mean different kind of users, they can be brought under the same roof if there are certain boundaries.
                
                First of all, I defined the co-living community as being formed by 15-20 people who are willing to share a spacious living room and kitchen, while having at the same time private spaces like one or two bedrooms, a bathroom and kitchenette (from my research in the master degree thesis, I concluded that 15-20 people is the perfect number for forming a small community that could share amenities; there are plenty of examples that reveal the fact that co-living might fail if the number of people sharing spaces is too high).
                
                Secondly, there are people who would like to be part of a community, but who like privacy and are not willing to share spaces like the living room or the kitchen, therefore prefer to live in private apartments.
                Therefore, both types of users can interact with each other in shared spaces like the rooftop, the gym, the greenhouse, the offices and workshops. As a result, one can choose his own degree of privacy.`
            ]
        },{
            type: ComponentType.NARROW_IMAGE,
            image: '08.bd-the-interview/07.BD.svg',
        }, {
            type: ComponentType.NARROW_TEXT,
            text: [
                `What role does the graphic language hold in relation to the project?

                Through the comic-book I was able to integrate a number of specific perspectives with the aim of telling stories about user interactions with the space, supplementing the conventional orthogonal projections (plans, sections, elevations). This language of representation enabled me to combine the drawing with its narrative through the presence of descriptive text and dialogue bubbles, creating the opportunity of expressing complex messages, in a brief manner. It injected the proposal with my personal touch in the project whilst synthesising the atmosphere.`
            ]
        }, {
            type: ComponentType.IMAGE_LEFT_RIGHT,
            imageLeft: '08.bd-the-interview/08.BD.jpg',
            imageRight: '08.bd-the-interview/09.BD.jpg'
        }, {
            type: ComponentType.NARROW_TEXT,
            text: [
                `What informed the choice of drawings through which you reveal the project?

                My interest in comic books and graphic novels lead the language of representation of the drawings. I decided to use this type of representation as I felt more comfortable and confident in expressing a specific message with the aid of these types of images. An important fact is that each image went through a fairly long drawing process as I kept returning to it, modifying details until I felt that it expresses the desired information. For example, one of the most representative drawings of the project (the illustration with the crowned sugar factory) took me almost three months to complete, being one of the first drawings to start and the last to finish.`
            ]
        }, {
            type: ComponentType.IMAGE_LEFT_RIGHT,
            imageLeft: '08.bd-the-interview/10.BD.jpg',
            imageRight: '08.bd-the-interview/11.BD.jpg'
        }, {
            type: ComponentType.IMAGE_LEFT_RIGHT,
            imageLeft: '08.bd-the-interview/12.BD.jpg',
            imageRight: '08.bd-the-interview/13.BD.jpg',
            margin: true
        }, {
            type: ComponentType.NARROW_TEXT,
            text: [
                `How powerful of a tool is drawing itself with architectural discourse?

                For me the drawing is a means to both articulate the project and also inject it with a personal identity. The drawing and sequence of these is pivotal in crystallising the very concept of the proposal in a clear and easy to imagine manner.`
            ]
        },{
            type: ComponentType.IMAGE_FULL,
            image: '08.bd-the-interview/14.BD-blend.jpg',
            imageMobile: '08.bd-the-interview/14.BD-blend.jpg',
            mixBlendMode: "multiply"
        }, {
            type: ComponentType.NARROW_TEXT,
            text: [
                `What is for you the architect’s most important tool?
                The drawing. Ideas are born only while drawing and synthesising the aims of a project can only be done with the aid of the drawing.
                One single image can capture the essence of a project.`
            ]
        },{
            type: ComponentType.IMAGE_FULL,
            image: '08.bd-the-interview/15.BD.jpg',
            imageMobile: '08.bd-the-interview/15.BD.jpg',
            mixBlendMode: "multiply"
        }]
    },
    'a-horsey-story': {
        metadata: {
            title: 'A HORSEY STORY',
            description: 'it could be about Bojack',
            image: '09.a-horsey-story/a-horsey-story-thumb.jpg'
        },
        components: [{
            type: ComponentType.IMAGE_FULL,
            image: '09.a-horsey-story/01.horsey.jpg',
            imageMobile: '09.a-horsey-story/09.horsey-mobile.jpg'
        },{
            type: ComponentType.NARROW_TEXT,
            text: [`
            The horse has always been present in the day-to-day life. It is part of the human history and also an important part of the society development, at the very beginning in the work process and in battle, then as a loyal partner in sports and therapy. The existence of this symbiosis, from which humans have gained the most, spans a period of almost six millennia. The value of this animal was much more known and appreciated in the past, when people lived near horses using their character and strength: they used them in war as “war machines” or as a mean of transport, for traction, or for plowing the land - by attaching a plow. This animal is so familiar to us, humans, that when we look at it, we forget the days when kings and queens rode horses, or the days when they filled the royal parades and elegant streets of pre-modern cities. 


            From military-noble to entertainment-populist, these values of the horse have played out throughout equestrian history, despite revolutions, wars and industrialization, in various environments with ascending or descending influence, but essentially unchanged, from the desire to show the skill of both the horse and the rider.
            The chosen theme within the diploma project refers to the revitalization of the Rădăuți Stud Farm and its hippodrome, by improving the existing equestrian center.`
            ]
        },{
            type: ComponentType.NARROW_IMAGE,
            image: '09.a-horsey-story/02.horsey.jpg',
            mixBlendMode: 'multiply',
            margin: true
        },{
            type: ComponentType.NARROW_TEXT,
            text: [`
            The site is located in the northeast of Romania - a small town surrounded by mountains and forests. Currently, the stud farm deals with breeding and training horses, as well as the organization or participation of the club in various competitions, but, due to the lack of necessary spaces, these activities have become increasingly rare. Although they work together, the stud farm (or the horse shelter), and the racing place are separated by a poorly circulated street. The racing area is surrounded by tall fir trees, like a vegetal enclosure which bites from the density of the build tissue, letting the city breath. On one side of the racetrack, there is an old-white tribune, small in size compared to the representation field. It is opened towards an area specially designed for ‘the jumping over fences’. Under the grandstand there are some horse boxes, currently unused.`]
        },{
            type: ComponentType.NARROW_IMAGE,
            image: '09.a-horsey-story/03.horsey.jpg',
        },{
            type: ComponentType.NARROW_TEXT,
            text: [`
            The stud area includes a public information point, with archives and employees’ offices, two stables, a garage, a workshop, a hayloft, several warehouses and a small training arena, with minimal dimensions and without a grandstand, so it is not open for the public. The stables are poorly ventilated and without enough light, which is a minus speaking of horse breeding quality. Also, several auxiliary spaces regarding the raising of animals in good conditions are deficient or missing, such as the veterinary office, the farriery, the harness room, some spaces for the theoretical learning and so on. Following this analysis and the potential of the space, I decided that the entire stud needs improvements to increase the quality of horse breeding, but also to increase the attraction in terms of sports and tourism and maybe to bring back the old leisure activities, such as horse racing. 
            `]
        },{
            type: ComponentType.IMAGE_FULL,
            image: '09.a-horsey-story/04.horsey-blend.jpg',
            imageMobile: '09.a-horsey-story/04.horsey-blend.jpg',
            mixBlendMode: 'multiply'
        },{
            type: ComponentType.IMAGE_FULL,
            image: '09.a-horsey-story/05.horsey-blend.jpg',
            imageMobile: '09.a-horsey-story/05.horsey-blend.jpg',
            mixBlendMode: 'multiply'
        },{
            type: ComponentType.IMAGE_FULL,
            image: '09.a-horsey-story/06.horsey.jpg',
            imageMobile: '09.a-horsey-story/06.horsey.jpg',
            margin: true
        },{
            type: ComponentType.IMAGE_FULL,
            image: '09.a-horsey-story/07.horsey.jpg',
            imageMobile: '09.a-horsey-story/07.horsey.jpg'
        },{
            type: ComponentType.IMAGE_FULL,
            image: '09.a-horsey-story/08.horsey.jpg',
            imageMobile: '09.a-horsey-story/08.horsey.jpg',
            margin: true
        },{
            type: ComponentType.IMAGE_FULL,
            image: '09.a-horsey-story/09.horsey-blend.jpg',
            imageMobile: '09.a-horsey-story/09.horsey-blend.jpg',
            mixBlendMode: 'multiply'
        },{
            type: ComponentType.IMAGE_FULL,
            image: '09.a-horsey-story/10.horsey-blend.jpg',
            imageMobile: '09.a-horsey-story/10.horsey-blend.jpg',
            mixBlendMode: 'multiply',
            margin: true
        },{
            type: ComponentType.IMAGE_FULL,
            image: '09.a-horsey-story/11.horsey-blend.jpg',
            imageMobile: '09.a-horsey-story/11.horsey-blend.jpg',
            mixBlendMode: 'multiply'
        }]
    },
    'first-plan': {
        metadata: {
            title: 'FIRST PLAN',
            description: `the first plan really nice plan you’ll see today`,
            image: '10.first-plan/the-first-plan-thumb.jpg'
        },
        components: [{
            type: ComponentType.IMAGE_FULL,
            image: '10.first-plan/01.firstplan.jpg',
            imageMobile: '10.first-plan/10.firstplan-mobile.jpg',
            mixBlendMode: 'multiply'
        },{
            type: ComponentType.IMAGE_FULL,
            image: '10.first-plan/02.firstplan.jpg',
            imageMobile: '10.first-plan/02.firstplan.jpg',
            margin: true
        }]
    },
    'second-plan': {
        metadata: {
            title: 'SECOND PLAN',
            description: `the second really nice plan you’ll see today`,
            image: '11.second-plan/the-second-plan-thumb.jpg'
        },
        components: [{
            type: ComponentType.IMAGE_FULL,
            image: '11.second-plan/01.second.jpg',
            imageMobile: '11.second-plan/11.secondplan-mobile.jpg',
            mixBlendMode: 'multiply'
        },{
            type: ComponentType.NARROW_IMAGE,
            image: '11.second-plan/02.second.jpg',
            margin: true
        }]
    },
    'third-plan': {
        metadata: {
            title: 'THIRD PLAN',
            description: `the third really nice plan you’ll see today`,
            image: '12.third-plan/the-third-plan-thumb.jpg'
        },
        components: [{
            type: ComponentType.IMAGE_FULL,
            image: '12.third-plan/01.third-blend.png',
            imageMobile: '12.third-plan/12.thirdplan-mobile.jpg',
            mixBlendMode: 'multiply'
        },{
            type: ComponentType.NARROW_IMAGE,
            image: '12.third-plan/02.third-plan.jpg',
            margin: true
        }]
    },
    'ba-505': {
        metadata: {
            title: 'BA505',
            description: 'this one is all about art and space',
            image: '13.ab505/ba505-thumb.jpg'
        },
        components: [{
            type: ComponentType.IMAGE_FULL,
            image: '13.ab505/01.AB505.jpg',
            imageMobile: '13.ab505/13.AB505-mobile.jpg',
        },{
            type: ComponentType.NARROW_IMAGE,
            image: '13.ab505/02.AB505-blend.jpg',
            mixBlendMode: 'multiply'
        },{
            type: ComponentType.NARROW_TEXT,
            text: [`
            "Sacred milieu of mutual withdrawal, letting be, love - three persons who would collapse into indifference and indifferentiation were it not for that free feminine spacing opening up between them: an Open that holds them at once together and apart."
            Khora
            `]
        },{
            type: ComponentType.NARROW_IMAGE,
            image: '13.ab505/03.AB505-blend.jpg',
            mixBlendMode: 'multiply'
        },{
            type: ComponentType.NARROW_TEXT,
            text: [
                `Plato's khora is an empty "placeless place from which everything that is derives."`,
                `The space in which we dwell is located in the gap in between, and in that spatial and temporal gap a creative, transformative movement from becoming to Being occurs.`
            ]
        },{
            type: ComponentType.NARROW_IMAGE,
            image: '13.ab505/04.AB505-blend.jpg',
            mixBlendMode: 'multiply'
        },{
            type: ComponentType.NARROW_TEXT,
            text: [
                `transcendence and immanence`
            ]
        },{
            type: ComponentType.NARROW_IMAGE,
            image: '13.ab505/05.AB505-blend.png',
            mixBlendMode: 'multiply'
        },{
            type: ComponentType.NARROW_TEXT,
            text: [
                `"We have no space of our own except as it is defined in relationship to other spaces, the multiple and interpenetrating spaces of our conscious life, our life together in society and ur life in relation to Being itself, to what we call God."`
            ]
        },{
            type: ComponentType.NARROW_IMAGE,
            image: '13.ab505/06.AB505-blend.png',
            mixBlendMode: 'multiply'
        },{
            type: ComponentType.NARROW_TEXT,
            text: [
                `Three Persons dancing around an empty space (khora).`
            ]
        }]
    },
    'space-travellers': {
        metadata: {
            title: 'SPACE TRAVELERS',
            description: 'space travel is easy',
            image: '22.space-traveller/space-travellers-thumb.jpg'
        },
        components: [{
            type: ComponentType.IMAGE_FULL,
            image: '22.space-traveller/01.st.jpg',
            imageMobile: '22.space-traveller/022.st-mobile.jpg',
        },{
            type: ComponentType.IMAGE_FULL,
            image: '22.space-traveller/02.st-blend.jpg',
            imageMobile: '22.space-traveller/02.st-blend.jpg',
            mixBlendMode: 'multiply',
            margin: true
        },{
            type: ComponentType.IMAGE_FULL,
            image: '22.space-traveller/03.st.jpg',
            imageMobile: '22.space-traveller/03.st.jpg',
        },{
            type: ComponentType.IMAGE_FULL,
            image: '22.space-traveller/04.st-blend.jpg',
            imageMobile: '22.space-traveller/04.st-blend.jpg',
            mixBlendMode: 'multiply',
            margin: true
        },{
            type: ComponentType.IMAGE_FULL,
            image: '22.space-traveller/05.st.jpg',
            imageMobile: '22.space-traveller/05.st.jpg',
        },{
            type: ComponentType.IMAGE_FULL,
            image: '22.space-traveller/06.st.jpg',
            imageMobile: '22.space-traveller/06.st.jpg',
            margin: true
        }]
    },
    'miscellaneous': {
        metadata: {
            title: 'MISCELLANEOUS',
            description: 'different nice things brought together',
            image: '14.miscellaneous/miscellaneous-thumb.jpg'
        },
        components: [{
            type: ComponentType.IMAGE_FULL,
            image: '14.miscellaneous/1.jpg',
            imageMobile: '14.miscellaneous/14.miscellaneous-mobile.jpg',
        },{
            type: ComponentType.IMAGE_FULL,
            image: '14.miscellaneous/2.jpg',
            imageMobile: '14.miscellaneous/2.jpg',
            margin: true
        },{
            type: ComponentType.IMAGE_FULL,
            image: '14.miscellaneous/3.jpg',
            imageMobile: '14.miscellaneous/3.jpg',
        },{
            type: ComponentType.IMAGE_FULL,
            image: '14.miscellaneous/4-blend.jpg',
            imageMobile: '14.miscellaneous/4-blend.jpg',
            mixBlendMode: 'multiply',
            margin: true
        },{
            type: ComponentType.IMAGE_FULL,
            image: '14.miscellaneous/5-blend.jpg',
            imageMobile: '14.miscellaneous/5-blend.jpg',
            mixBlendMode: 'multiply',
        },{
            type: ComponentType.IMAGE_FULL,
            image: '14.miscellaneous/6-blend.jpg',
            imageMobile: '14.miscellaneous/6-blend.jpg',
            mixBlendMode: 'multiply',
            margin: true
        },{
            type: ComponentType.IMAGE_FULL,
            image: '14.miscellaneous/7-blend.jpg',
            imageMobile: '14.miscellaneous/7-blend.jpg',
            mixBlendMode: 'multiply',
        },{
            type: ComponentType.IMAGE_FULL,
            image: '14.miscellaneous/8.jpg',
            imageMobile: '14.miscellaneous/8.jpg',
            margin: true
        },{
            type: ComponentType.IMAGE_FULL,
            image: '14.miscellaneous/9.jpg',
            imageMobile: '14.miscellaneous/9.jpg',
        },{
            type: ComponentType.IMAGE_FULL,
            image: '14.miscellaneous/10.jpg',
            imageMobile: '14.miscellaneous/10.jpg',
            margin: true
        },{
            type: ComponentType.IMAGE_FULL,
            image: '14.miscellaneous/11.jpg',
            imageMobile: '14.miscellaneous/11.jpg',
        },{
            type: ComponentType.IMAGE_FULL,
            image: '14.miscellaneous/12-blend.jpg',
            imageMobile: '14.miscellaneous/12-blend.jpg',
            mixBlendMode: 'multiply',
            margin: true
        },{
            type: ComponentType.IMAGE_FULL,
            image: '14.miscellaneous/13-blend.jpg',
            imageMobile: '14.miscellaneous/13-blend.jpg',
            mixBlendMode: 'multiply',
        },{
            type: ComponentType.IMAGE_FULL,
            image: '14.miscellaneous/14-blend.jpg',
            imageMobile: '14.miscellaneous/14-blend.jpg',
            mixBlendMode: 'multiply',
            margin: true
        },{
            type: ComponentType.IMAGE_FULL,
            image: '14.miscellaneous/15.jpg',
            imageMobile: '14.miscellaneous/15.jpg',
        },{
            type: ComponentType.IMAGE_FULL,
            image: '14.miscellaneous/16.jpg',
            imageMobile: '14.miscellaneous/16.jpg',
            margin: true
        },{
            type: ComponentType.IMAGE_FULL,
            image: '14.miscellaneous/17.jpg',
            imageMobile: '14.miscellaneous/17.jpg',
        },{
            type: ComponentType.IMAGE_FULL,
            image: '14.miscellaneous/18.jpg',
            imageMobile: '14.miscellaneous/18.jpg',
            margin: true
        },{
            type: ComponentType.IMAGE_FULL,
            image: '14.miscellaneous/19.jpg',
            imageMobile: '14.miscellaneous/19.jpg',
        },{
            type: ComponentType.IMAGE_FULL,
            image: '14.miscellaneous/20.jpg',
            imageMobile: '14.miscellaneous/20.jpg',
            margin: true
        },{
            type: ComponentType.IMAGE_FULL,
            image: '14.miscellaneous/21.jpg',
            imageMobile: '14.miscellaneous/21.jpg',
        },{
            type: ComponentType.IMAGE_FULL,
            image: '14.miscellaneous/22.jpg',
            imageMobile: '14.miscellaneous/22.jpg',
            margin: true
        },{
            type: ComponentType.IMAGE_FULL,
            image: '14.miscellaneous/23.jpg',
            imageMobile: '14.miscellaneous/23.jpg',
        },{
            type: ComponentType.IMAGE_FULL,
            image: '14.miscellaneous/24.jpg',
            imageMobile: '14.miscellaneous/24.jpg',
            margin: true
        },{
            type: ComponentType.IMAGE_FULL,
            image: '14.miscellaneous/25.jpg',
            imageMobile: '14.miscellaneous/25.jpg',
        }]
    },
    'parish-house': {
        metadata: {
            title: 'PARISH HOUSE',
            description: 'the most elementary house you’ll see today',
            image: '15.parish-house/the-parish-house-thumb.jpg'
        },
        components: [{
            type: ComponentType.IMAGE_FULL,
            image: '15.parish-house/01.parish-house.jpg',
            imageMobile: '15.parish-house/15.parish-house-mobile.jpg',
        },{
            type: ComponentType.IMAGE_FULL,
            image: '15.parish-house/02.parish-house-blend.jpg',
            imageMobile: '15.parish-house/02.parish-house-blend.jpg',
            mixBlendMode: 'multiply'
        }]
    },
    'longhouse': {
        metadata: {
            title: 'THE LONG HOUSE',
            description: 'the longest house you’ll see today',
            image: '16.the-long-house/the-long-house-thumb.jpg'
        },
        components: [{
            type: ComponentType.IMAGE_FULL,
            image: '16.the-long-house/01.longhouse.jpg',
            imageMobile: '16.the-long-house/16.longhouse-mobile.jpg',
        },{
            type: ComponentType.IMAGE_FULL,
            image: '16.the-long-house/02.longhouse.jpg',
            imageMobile: '16.the-long-house/02.longhouse.jpg',
            margin: true
        },{
            type: ComponentType.IMAGE_FULL,
            image: '16.the-long-house/03.longhouse.svg',
            imageMobile: '16.the-long-house/03.longhouse.svg',
        }]
    },
    'garden-house': {
        metadata: {
            title: 'GARDEN HOUSE',
            description: `the nicest house you’ll see today`,
            image: '18.gardenhouse/the-garden-house-thumb.jpg'
        },
        components: [{
            type: ComponentType.IMAGE_FULL,
            image: '18.gardenhouse/01.gardenhouse.jpg',
            imageMobile: '18.gardenhouse/18.garden-house-mobile.jpg',
        },{
            type: ComponentType.IMAGE_FULL,
            image: '18.gardenhouse/02.gardenhouse-blend.jpg',
            imageMobile: '18.gardenhouse/02.gardenhouse-blend.jpg',
            mixBlendMode: 'multiply',
            margin: true
        },{
            type: ComponentType.IMAGE_FULL,
            image: '18.gardenhouse/03.gardenhouse.jpg',
            imageMobile: '18.gardenhouse/03.gardenhouse.jpg',
        },{
            type: ComponentType.IMAGE_FULL,
            image: '18.gardenhouse/04.gardenhouse.jpg',
            imageMobile: '18.gardenhouse/04.gardenhouse.jpg',
            margin: true
        }]
    },
    'hermitage': {
        metadata: {
            title: 'HERMITAGE',
            description: `the holiest house you’ll see today`,
            image: '019.hermitage/a-hermitage-thumb.jpg'
        },
        components: [{
            type: ComponentType.IMAGE_FULL,
            image: '019.hermitage/01.hermitage-blend.jpg',
            imageMobile: '019.hermitage/019.hermitage-mobile.jpg',
            mixBlendMode: 'multiply'
        },{
            type: ComponentType.IMAGE_FULL,
            image: '019.hermitage/02.hermitage-blend.jpg',
            imageMobile: '019.hermitage/02.hermitage-blend.jpg',
            mixBlendMode: 'multiply',
            margin: true
        }]
    },
    'storage-hall': {
        metadata: {
            title: 'STORAGE HALL',
            description: 'or how we call it, the boring house',
            image: '020.storage-hall/a-storage-hall-thumb.jpg'
        },
        components: [{
            type: ComponentType.IMAGE_FULL,
            image: '020.storage-hall/01.storage-hall.jpg',
            imageMobile: '020.storage-hall/20.storage-hall-mobile.jpg',
        },{
            type: ComponentType.IMAGE_FULL,
            image: '020.storage-hall/02.storage-hall-blend.jpg',
            imageMobile: '020.storage-hall/02.storage-hall-blend.jpg',
            mixBlendMode: 'multiply',
            margin: true
        }]
    },
    'seaside-house': {
        metadata: {
            title: 'SEASIDE HOUSE',
            description: 'now you crave for the sea',
            image: '21.seaside-house/the-seaside-house-thumb.jpg'
        },
        components: [{
            type: ComponentType.IMAGE_FULL,
            image: '21.seaside-house/01.seaside.jpg',
            imageMobile: '21.seaside-house/21.seaside-house-mobile.jpg',
        },{
            type: ComponentType.IMAGE_FULL,
            image: '21.seaside-house/02.seaside.jpg',
            imageMobile: '21.seaside-house/02.seaside.jpg',
            margin: true
        }]
    },
    'paperclip': {
        metadata: {
            title: 'PAPERCLIP',
            description: `it’s definitely not what it says`,
            image: '23.paperclip/the-paperclip-thumb.jpg'
        },
        components: [{
            type: ComponentType.IMAGE_FULL,
            image: '23.paperclip/01.paperclip.jpg',
            imageMobile: '23.paperclip/23.paperclip-mobile.jpg',
            mixBlendMode: 'multiply'
        },{
            type: ComponentType.NARROW_IMAGE,
            image: '23.paperclip/02.paperclip.jpg',
            margin: true
        },{
            type: ComponentType.NARROW_TEXT,
            text: [`
            Prima impresie asupra Pieței Unirii a fost că locul oferă potențialul unei Agore, locul deschis al comunității, al opririi la “umbra” dealului cetății. Amenajarea recentă conturează acest spațiu și așează un paviment ce leagă piața de strada imediat adiacentă. Totuși, diferența de nivel rezultată în urma construirii parcării subterane pare să rupă legătura transversal și să contureze un centru scobit al acesteia. Ne-am propus să folosim acest caracter nou creat să păstrăm astfel cât mai mult din pavajul existent, iar intervenția noastră să adauge un strat fragil ce leagă și transversal piața.
Gradenele longitudinale, construcții metalice diafane, ușoare, grupează locurile de stat dar sunt și mijloc de legare, de traversare, de rețesere a posibilităților de parcurgere a pieței. Ne dorim ca spațiul pieței să rămână liber pentru a putea fi umplut de diferitele evenimente și/sau momente ale anului. Piața devine un suport al activității. Oamenii sunt cei care pot decide cum și unde se așează și cum și pe unde se plimbă.
Propunem mici intervenții în pavimentul pieței ce au menirea ca aceasta să devină un loc întins, nemăsurat de geometria actuală a celor trei locuri desenate cu piatră de culoare albă. Firul de apă propus accentuează această întindere longitudinală a spațiului și orientează către biserică. 
            `],
        },{
            type: ComponentType.IMAGE_LEFT_RIGHT,
            imageLeft: '23.paperclip/03.paperclip.png',
            imageRight: '23.paperclip/04.paperclip.png',
            margin: true
        },{
            type: ComponentType.IMAGE_FULL,
            image: '23.paperclip/05.paperclip-blend.jpg',
            imageMobile: '23.paperclip/05.paperclip-blend.jpg',
            mixBlendMode: 'multiply'
        },{
            type: ComponentType.IMAGE_FULL,
            image: '23.paperclip/06.paperclip-blend.jpg',
            imageMobile: '23.paperclip/06.paperclip-blend.jpg',
            mixBlendMode: 'multiply',
            margin: true
        },{
            type: ComponentType.IMAGE_FULL,
            image: '23.paperclip/07.paperclip-blend.jpg',
            imageMobile: '23.paperclip/07.paperclip-blend.jpg',
            mixBlendMode: 'multiply'
        },{
            type: ComponentType.IMAGE_FULL,
            image: '23.paperclip/08.paperclip-blend.jpg',
            imageMobile: '23.paperclip/08.paperclip-blend.jpg',
            mixBlendMode: 'multiply',
            margin: true
        },{
            type: ComponentType.NARROW_IMAGE,
            image: '23.paperclip/09.paperclip-blend.jpg',
            mixBlendMode: 'multiply'
        },{
            type: ComponentType.NARROW_IMAGE,
            image: '23.paperclip/10.paperclip.jpg',
            margin: true
        },{
            type: ComponentType.NARROW_IMAGE,
            image: '23.paperclip/11.paperclip-blend.jpg',
            mixBlendMode: 'multiply'
        }]
    },
    'ping-pong-6': {
        metadata: {
            title: 'PINGPONG+6',
            description: 'the original ping-pong hit',
            image: '24.pp-6/ping-pong+6-thumb.jpg'
        },
        components: [{
            type: ComponentType.IMAGE_FULL,
            image: '24.pp-6/01.pp+6.jpg',
            imageMobile: '24.pp-6/24.pp-6-mobile.jpg',
        },{
            type: ComponentType.NARROW_TEXT,
            text: [`Winning project of Romanian Design Week design competition, Ping-Pong + 6 is a hexagonal ping-pong table, delivered as a pack of 6 equilateral triangles. The hexagonal shape allows countless play scenarios, for 2-6 players, with balls simultaneously bouncing in 6+ directions. The game can be played by 2,4 or 6 players (even an odd number of players), using how many balls they want, 1, 2, 3, 4....`],
            margin: true
        },{
            type: ComponentType.NARROW_IMAGE,
            image: '24.pp-6/02.pp+6-blend.jpg',
            mixBlendMode: 'multiply'
        },{
            type: ComponentType.NARROW_IMAGE,
            image: '24.pp-6/03.pp+6-blend.jpg',
            mixBlendMode: 'multiply',
            margin: true
        },{
            type: ComponentType.NARROW_IMAGE,
            image: '24.pp-6/04.pp+6-blend.jpg',
            mixBlendMode: 'multiply'
        },{
            type: ComponentType.NARROW_IMAGE,
            image: '24.pp-6/05.pp+6-blend.jpg',
            mixBlendMode: 'multiply',
            margin: true
        },{
            type: ComponentType.NARROW_IMAGE,
            image: '24.pp-6/06.pp+6.jpg'
        }]
    },
    'loooop': {
        metadata: {
            title: 'LOOP',
            description: 'it could go in a loop forever',
            image: '25.looop/artboard-1-thumb.jpg'
        },
        components: [{
            type: ComponentType.IMAGE_FULL,
            image: '25.looop/01.loop.jpg',
            imageMobile: '25.looop/25.looop-mobile.jpg',
            mixBlendMode: 'multiply'
        },{
            type: ComponentType.IMAGE_FULL,
            image: '25.looop/02.loop.jpg',
            imageMobile: '25.looop/02.loop.jpg',
            mixBlendMode: 'multiply',
            margin: true
        }]
    },
    'enescu-house': {
        metadata: {
            title: 'GEORGE ENESCU MEMORIAL HOUSE',
            description: 'the most beautiful house in Bucharest',
            image: '026.enescu-house/the-most-beautiful-house-thumb.jpg'
        },
        components: [{
            type: ComponentType.IMAGE_FULL,
            image: '026.enescu-house/enescuhouse.jpg',
            imageMobile: '026.enescu-house/26.enescu-house-mobile.jpg',
        }]
    },
}