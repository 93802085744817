import React from 'react';

import { Box, Chip, Divider, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';

interface SectionProps {
  title?: string
  style?: Object
  id?: string
  useEmptyDivider?: boolean
}

const getStyles = (theme: Theme) => ({
  root: {
    width: '100%',
  },
  button: {
    minWidth: '220px',
    margin: '20px 0',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    borderRadius: '20px',
    "& .MuiChip-label": {
      fontSize: '1.2rem'
    }
  },
  divider: {
    "& span": {
      // Kepp that dot spacing.
      padding: 0.2
    },
    "&::before, &::after": {
      borderTop: `3px dotted ${theme.palette.secondary.main}`,
    },
  }
});

export const Section: React.FunctionComponent<SectionProps> = ({
  children,
  title,
  style = {},
  id = "",
  useEmptyDivider = false
}) => {
  const theme = useTheme();
  const classes = getStyles(theme);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box id={id} sx={{
       ...classes.root,
       ...style
      }}>
      {title && (
        <Divider sx={classes.divider}>
          <Chip label={<Typography fontSize={isMobile ? '1rem' : '1.2rem'} color={theme.palette.primary.main} letterSpacing={2}>{title}</Typography>} sx={classes.button}/>
        </Divider>
      )}
      {useEmptyDivider && (
        <Divider sx={classes.divider}><></></Divider>
      )}
      {children}
    </Box>
  );
};
