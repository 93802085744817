import React from 'react';

import {
    Fade, List, ListItem, ListItemButton, ListItemText, Popper, Theme, Typography, useTheme
} from '@mui/material';

import { ReactComponent as CruceIcon } from '../assets/cruce.svg';
import { SectionData } from '../data';

interface SectionListProps {
  sections: SectionData[]
  onOpen: (
    open: boolean, 
    target: SVGElement | null, 
    callback: (target: SVGElement | null) => void
  ) => void
  onSelect: (id: string) => void
  orientation: 'center' | 'left'
}

const getStyles = (theme: Theme) => ({
  root: {
    ["& .MuiPopover-paper"]: {
      border: "none",
      backgroundColor: theme.palette.background.default,
      boxShadow: "none",
    },
  },
  list: {
    padding: 0
  },
  listItem: {
    backgroundColor: theme.palette.background.default,
    padding: 0,
    minWidth: "300px",
  },
  listButton: {},
  listItemText: {
    textAlign: "left",
    fontWeight: 600,
    margin: 0,
    ['& span']: {
      fontSize: '1.4rem',
      
    }
    
  },
});

export const SectionList: React.FunctionComponent<SectionListProps> = ({
  sections,
  orientation,
  onSelect,
  onOpen
}) => {
  const theme = useTheme();
  const classes = getStyles(theme);
  const [anchorEl, setAnchorEl] = React.useState<SVGElement | null>(null);

  React.useEffect(() => {
    const handleScroll = () => {
      if(anchorEl) setAnchorEl(null)
    }

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [anchorEl]);

  const handleClick = (event: React.MouseEvent<SVGElement>) => {
    onOpen(!anchorEl, event.target as SVGElement | null, (target) => {
        setAnchorEl(target as SVGElement | null);
    })
  };

  const open = Boolean(anchorEl);
  const id = open ? 'virtual-element-popper' : undefined;
  return (
    <>
      <CruceIcon
        aria-describedby={id}
        fill={theme.palette.primary.contrastText}
        onClick={handleClick}
      />
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        transition
        placement={orientation === "center" ? "bottom" : "bottom-start"}
        sx={classes.root}
        modifiers={[
          {
            name: 'flip',
            enabled: true,
            options: {
              altBoundary: true,
              rootBoundary: 'viewport',
              padding: 8,
            },
          },
          {
            name: 'preventOverflow',
            enabled: false,
            options: {
              altAxis: false,
              altBoundary: false,
              tether: false,
              rootBoundary: 'document',
              padding: 8,
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={{
            appear: 350,
            exit: 0
          }}>
            <List sx={classes.list}>
              {sections.map((section) => (
                <ListItem key={section.id} sx={classes.listItem}>
                  <ListItemButton
                    sx={classes.listButton}
                    component="a"
                    href={`#${section.id}`}
                    onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                      e.preventDefault();
                      onOpen(false, anchorEl, () => {
                        setAnchorEl(null);
                        onSelect(section.id)
                      })
                    }}
                  >
                    <ListItemText
                      sx={{
                        ...classes.listItemText,
                        ...(orientation === 'center' ? { textAlign: 'center' } : {})
                      }}
                      primary={(<Typography variant="h5" letterSpacing={2}>{section.name}</Typography>)}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Fade>
        )}
        
      </Popper>
    </>
  );
};
