import React from 'react';
import { AttentionSeeker, Slide } from 'react-awesome-reveal';
import TextLoop from 'react-text-loop';
import { Link } from 'react-router-dom'

import { Box, Chip, Grid, keyframes, Link as MuiLink, Typography, useMediaQuery } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';

import { SectionData } from '../data';
import { SectionList } from './SectionList';
import { ReactComponent as RombIcon } from '../assets/romb.svg';


interface HeaderProps {
  sections: SectionData[];
}

enum MENU_BUTTON_SIZE {
  LARGE = "40px",
  SMALL = "40px",
}
enum DOT_SIZE {
  LARGE = "25px",
  SMALL = "20px",
}

const getAnimation = keyframes`
  0%   { transform: scale(1,1)      translateY(0); }
  10%  { transform: scale(1.1,.9)   translateY(0); }
  30%  { transform: scale(.9,1.1)   translateY(-500px); }
  50%  { transform: scale(1.05,.95) translateY(0); }
  57%  { transform: scale(1,1)      translateY(-50px); }
  64%  { transform: scale(1,1)      translateY(0); }
  100% { transform: scale(1,1)      translateY(0); }
`;

const getStyles = (theme: Theme) => ({
  root: {
    minHeight: "100vh",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      height: "100%",
      alignContent: 'center',
      overflow: 'hidden'
    },
    [theme.breakpoints.up("md")]: {
      padding: "70px",
    }
  },
  wrapper: {
    backgroundImage: `url(${window.location.origin}/images/logo.svg)`,
    backgroundRepeat: "no-repeat",
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-evenly",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      height: "fit-content",
    },
  },
  logoDot: {
    height: DOT_SIZE.LARGE,
    width: DOT_SIZE.LARGE,
    [theme.breakpoints.down("md")]: {
      height: DOT_SIZE.SMALL,
      width: DOT_SIZE.SMALL,
      marginTop: `-${DOT_SIZE.SMALL}`,
      alignSelf: "flex-end",
    },
    backgroundColor: theme.palette.primary.contrastText,
    animation: `${getAnimation} ease-in 2s`,
    animationTimingFunction: "cubic-bezier(0, 1.35, 0.4, 1.68)",
  },
  logoWrapper: { alignSelf: "flex-end" },
  logoContainer: {
    flexDirection: "row",
    display: "flex",
    alignItems: "flex-end",
  },
  menuItem: {
    margin: "0 40px",
    width: MENU_BUTTON_SIZE.LARGE,
    height: MENU_BUTTON_SIZE.LARGE,
    [theme.breakpoints.down("md")]: {
      width: MENU_BUTTON_SIZE.SMALL,
      height: MENU_BUTTON_SIZE.SMALL,
    },
    [":hover"]: {
      WebkitTransform: "rotate(405deg)",
      msTransform: "rotate(405deg)",
      transform: "rotate(405deg)",
    },
    transition: "transform .25s, opacity .25s",
  },
  bottomMenu: {
    display: "flex",
    flexDirection: "row",
    height: MENU_BUTTON_SIZE.LARGE,
    alignItems: "center",
    "& .MuiChip-label": {
      width: "150px",
      textAlign: "center",
      fontSize: "18px",
      fintWeight: 600,
    },
  },
  jumpNext: {
    width: 0,
    height: 0,
    borderLeft: "20px solid transparent",
    borderRight: "20px solid transparent",
    borderTop: `30px solid ${theme.palette.secondary.main}`,
  },
});

export const Header: React.FunctionComponent<HeaderProps> = ({
  sections,
}) => {
  const theme = useTheme();
  const innerRef = React.useRef(null);
  let isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const classes = getStyles(theme);
  const [triggerAnimation, setTriggerAnimation] = React.useState<boolean>(false);
  const [sectionMenuOpen, setSectionMenuOpen] = React.useState<boolean>(false);
  // Workaround for hydration issue caused by react-snap
  // where the page would display as mobile on web on the first load.
  const [hasMounted, setHasMounted] = React.useState(false);
  React.useEffect(() => {
    setHasMounted(true);
  }, []);
  
  React.useLayoutEffect(() => {
    setTriggerAnimation(true);
  }, []);

  // Effect used for adding a mouse event handler to increase the size of the cursor
  React.useEffect(() => {
    const element = innerRef.current;
    function onMouseHover() {
      // @ts-ignore
      TweenMax.to($circle, 0.3, {
        scale: 4,
      });
    }
    function onMouseHoverOut() {
      // @ts-ignore
      TweenMax.to($circle, 0.3, {
        scale: 1,
      });
    }
    
    if (!element) {
      return
    }

    // @ts-ignore
    element.addEventListener("mouseenter", onMouseHover);
    // @ts-ignore
    element.addEventListener("mouseleave", onMouseHoverOut);

    return () => {
      // @ts-ignore
      element.removeEventListener("mouseenter", onMouseHover);
      // @ts-ignore
      element.removeEventListener("mouseleave", onMouseHoverOut);
    };
  }, []);

  const description = (
    <>
      <span>WE ARE A CREATIVE STUDIO THAT MAKES</span>
      <span
        ref={innerRef}
        className="hoverable"
        style={{ 
          color: "#1771A3", 
          display: 'flex',
          justifyContent: isMobile ? 'center' : 'flex-start'
        }}
      >
        <TextLoop springConfig={{ stiffness: 180, damping: 8 }}>
          <span>MEMORABLE</span>
          <span>AWESOME</span>
          <span>BRILLIANT</span>
        </TextLoop>
      </span>
      <span>STORIES FOR YOU</span>
    </>
  )
  const scrollToElement = (id: string) => {
    const sectionElement = document.querySelector(`#${id}`)
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  if (!hasMounted) {
    return null;
  }

  // Use a different header design for mobile.
  /* ! Web needs to be first in order to render is properly using react-snap ! */
  if (!isMobile) {
    return (
      <Grid container sx={classes.root}>
        <Box sx={classes.wrapper}>
          <Grid item lg={6} className="logo" sx={classes.logoWrapper}>
            <Grid sx={classes.logoContainer}>
              {triggerAnimation && <Box sx={classes.logoDot}></Box>}
            </Grid>
          </Grid>
          <Grid
            item
            lg={7}
            className="menu"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              {triggerAnimation && (
                <>
                  <Box sx={classes.menuItem}>
                    <AttentionSeeker>
                    <Link to={'/team'}>
                      <RombIcon
                        fill={theme.palette.primary.contrastText}
                      />
                      </Link>
                    </AttentionSeeker>
                  </Box>
                  <Box sx={classes.menuItem}>
                    <AttentionSeeker delay={100}>
                      <SectionList 
                        orientation="left" 
                        sections={sections}
                        onOpen={(open, target, callback) => {
                          callback(open ? target : null)
                        }}
                        onSelect={scrollToElement}
                      />
                    </AttentionSeeker>
                  </Box>
                </>
              )}
              <Box
                sx={{
                  height: MENU_BUTTON_SIZE.LARGE,
                  width: "200px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "22px",
                }}
              >
                <MuiLink
                  onClick={() => scrollToElement('footer')}
                  underline="hover"
                  color={theme.palette.secondary.main}
                >
                  <Typography variant="h5" color={theme.palette.secondary.main}>
                    contacts
                  </Typography>
                </MuiLink>
              </Box>
            </Box>
            <Box
              sx={{
                fontSize: "4rem",
                fontWeight: 600,
              }}
            >
              <Typography variant="caption" fontSize="4rem" fontWeight="600">
                  {description}
              </Typography>
            </Box>
            <Box sx={classes.bottomMenu}>
              <Chip
                label="explore"
                variant="outlined"
                component="a"
                color="secondary"
                size="medium"
                sx={{ cursor: 'none'}}
                onClick={() => {
                  const min = 0;
                  const max = sections.length;
                  const randomId = Math.floor(Math.random() * (max - min) + min);
                  scrollToElement(sections[randomId].id)
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "200px",
                }}
              >
                <AttentionSeeker delay={300}>
                  <a onClick={() => scrollToElement(sections[0].id)}>
                    <Box sx={classes.jumpNext} />
                  </a>
                </AttentionSeeker>
              </Box>
            </Box>
          </Grid>
        </Box>
      </Grid>
    );
  }

  return (
    <Grid
      container
      sx={classes.root}
    >
      <Box>
        <Slide direction="down">
          <Box sx={{
            height: '35vh',
            maxWidth: '35vh',
            backgroundColor: theme.palette.secondary.main,
            clipPath: 'circle(50% at 50%)',
            transform: 'scale(1.8) translateY(-20%)',
            margin: '0 auto'
          }}></Box>
        </Slide>
      </Box>
      <Grid
        item
        lg={12}
        className="menu"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: sectionMenuOpen ? "flex-start" : "space-around",
          alignItems: "center",
          height: '45vh',
          margin: '45px'
        }}
      >
        {triggerAnimation && (
            <>
              <Box sx={classes.menuItem}>
                <AttentionSeeker delay={300}>
                <Link to={'/team'}>
                  <RombIcon
                    fill={theme.palette.primary.contrastText}
                  />
                </Link>
                
                </AttentionSeeker>
              </Box>
              <Box sx={{
                textAlign: 'center',
                transition: "all 0.6s ease-in",
                ...(sectionMenuOpen ? {
                  opacity: sectionMenuOpen ? 0 : 1,
                  overflow: 'hidden',
                  height: '0',
                  transform: 'translateY(0px)',
                  transition: 'all 0.6s ease-out',
                } : {})
              }}>
                <Typography variant="caption" fontSize="1.7rem" fontFamily='DM sa' fontWeight={600} lineHeight={1.2}>
                  {description}
                </Typography>
              </Box>
              <Box sx={{
                  ...classes.menuItem,
                  marginTop: sectionMenuOpen ? '20px' : 0
                }}>
                  <AttentionSeeker delay={500}>
                    <SectionList 
                      orientation="center" 
                      sections={sections}
                      onOpen={(open, target, callback) => {
                        setSectionMenuOpen(open)
                        setTimeout(() => callback(open ? target : null), 300)
                      }}
                      onSelect={scrollToElement}
                    />
                  </AttentionSeeker>
              </Box>
            </>
          )}
      </Grid>
    </Grid>
  );
};
