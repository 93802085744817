import { ReactComponent as Banana } from '../assets/01.banana.svg';
import { ReactComponent as HighTech } from '../assets/02.high-tech.svg';
import { ReactComponent as LoveSex } from '../assets/03.love-sex-and-real-estate.svg';
import { ReactComponent as PingPongAsap } from '../assets/04.pingpong-6-x-asap.svg';
import { ReactComponent as ApartA } from '../assets/05.apartment-a.svg';
import { ReactComponent as BeyondDewlling } from '../assets/06.beyond-dwelling-album.svg';
import { ReactComponent as DramaMemorial } from '../assets/07.drama-memorial.svg';
import { ReactComponent as BeyondInterview } from '../assets/08.beyond-dwelling-the-interview.svg';
import { ReactComponent as Horsie } from '../assets/09.horsie.svg';
import { ReactComponent as ApartmentX } from '../assets/10.apartament-x.svg';
import { ReactComponent as ApartmentY } from '../assets/11.apartament-y.svg';
import { ReactComponent as ApartmentZ } from '../assets/12.apartament-z.svg';
import { ReactComponent as ApartmentAB } from '../assets/13.apartament-AB.svg';
import { ReactComponent as Visualizations } from '../assets/14.visualizations.svg';
import { ReactComponent as ParishHouse } from '../assets/15.parish-house.svg';
import { ReactComponent as Restoration } from '../assets/16.restoration.svg';
// import { ReactComponent as Attic } from "../assets/17.attic.svg";
import { ReactComponent as GardenHouse } from '../assets/18.garden-house.svg';
import { ReactComponent as Heritage } from '../assets/19.heritage.svg';
import { ReactComponent as StorageHall } from '../assets/20.storage-hall.svg';
import { ReactComponent as SeasideHouse } from '../assets/21.seaside-house.svg';
import { ReactComponent as Moon } from '../assets/22.moon.svg';
import { ReactComponent as Paperclip } from '../assets/23.paperclip.svg';
import { ReactComponent as PingPongRDW } from '../assets/24.pingpong-6-x-RDW.svg';
import { ReactComponent as Loop } from '../assets/25.loooop.svg';
import { ReactComponent as Photo } from '../assets/26.photo.svg';

export enum CollaboratorAsset {
  BIG_CIRCLE,
  MEDIUM_CIRCLE,
  SMALL_SQUARE,
  SMALL_CIRCLE
}
export interface CollaboratorData {
  name: string
  position: string
  asset: CollaboratorAsset
}

export interface SectionData {
  name: string;
  id: string;
}

export interface ProjectData {
  image: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  title: string;
  slug: string;
}
export interface ProjectDataList {
  [sectionId: string]: ProjectData[];
}

const sections: SectionData[] = [
  {
    name: "fulfilled",
    id: "fulfilled",
  },
  {
    name: "imagination",
    id: "imagination",
  },
  {
    name: "tangible",
    id: "tangible",
  },
  {
    name: "competitions",
    id: "competitions",
  },
];

const projects: ProjectDataList = {
  fulfilled: [
    {
      image: Banana,
      title: "banana",
      slug: 'banana'
    },
    {
      image: HighTech,
      title: "hightech center",
      slug: 'hitech-center'
    },
    {
      image: LoveSex,
      title: "love sex and real estate",
      slug: 'love-sex-and-real-estate'
    },
    {
      image: PingPongAsap,
      title: "pingpong+6 x asap",
      slug: 'pingpong-6-asap'
    },
    {
      image: ApartA,
      title: "gheorgheni 34",
      slug: 'an-apartment'
    },
    {
      image: BeyondDewlling,
      title: "beyond dwelling (the album)",
      slug: 'beyong-dewlling-album'
    },
  ],
  imagination: [
    {
      image: DramaMemorial,
      title: "drama memorial",
      slug: 'drama-memorial'
    },
    {
      image: BeyondInterview,
      title: "beyond dwelling - the interview",
      slug: 'beyong-dwelling-the-interview'
    },
    {
      image: Horsie,
      title: "a horsey story",
      slug: 'a-horsey-story'
    },
    {
      image: ApartmentX,
      title: "first plan",
      slug: 'first-plan'
    },
    {
      image: ApartmentY,
      title: "second plan",
      slug: 'second-plan'
    },
    {
      image: ApartmentZ,
      title: "third plan",
      slug: 'third-plan'
    },
    {
      image: ApartmentAB,
      title: "ba 505",
      slug: 'ba-505'
    },
    {
      image: Moon,
      title: "space travellers",
      slug: 'space-travellers'
    },
    {
      image: Visualizations,
      title: "miscellaneous",
      slug: 'miscellaneous'
    },
  ],
  tangible: [
    {
      image: ParishHouse,
      title: "parish house",
      slug: 'parish-house'
    },
    {
      image: Restoration,
      title: "longhouse",
      slug: 'longhouse'
    },
    {
      image: GardenHouse,
      title: "garden house",
      slug: 'garden-house'
    },
    {
      image: Heritage,
      title: "hermitage",
      slug: 'hermitage'
    },
    {
      image: StorageHall,
      title: "storage hall",
      slug: 'storage-hall'
    },
    {
      image: SeasideHouse,
      title: "seaside house",
      slug: 'seaside-house'
    },
  ],
  competitions: [
    {
      image: Paperclip,
      title: "paperclip",
      slug: 'paperclip'
    },
    {
      image: PingPongRDW,
      title: "pingpong+6 x RDW",
      slug: 'ping-pong-6'
    },
    {
      image: Loop,
      title: "loooop",
      slug: 'loooop'
    },
    {
      image: Photo,
      title: "george enescu",
      slug: 'enescu-house'
    },
  ],
};

const teamData: CollaboratorData[] = [
  {
    name: "didier chifan",
    position: "architect",
    asset: CollaboratorAsset.BIG_CIRCLE
  },
  {
    name: "dana rosca",
    position: "architect",
    asset: CollaboratorAsset.SMALL_CIRCLE
  },
  {
    name: "alexandru pintilie",
    position: "structural engineer",
    asset: CollaboratorAsset.SMALL_SQUARE
  },
  {
    name: "barat alexandru",
    position: "architect",
    asset: CollaboratorAsset.MEDIUM_CIRCLE
  },
];


const data = {
  sections,
  teamData,
  projects,
};

export default data;
