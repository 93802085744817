import React from 'react';
import { Box, Grid, Link, Typography, useMediaQuery, useTheme } from '@mui/material';

import { ComponentType, ProjectComponent, ProjectDetails } from '../data/projectData';
import { Carousel } from './Carousel';

export interface ProjectProps {
  data: ProjectDetails
}

const renderProjectComponent = (component: ProjectComponent, isMobile: boolean, index: number | string): JSX.Element => {
  const mixBlendMode = component?.mixBlendMode ? component.mixBlendMode : 'normal'
  const imageStyle: React.CSSProperties = {
    objectFit: 'cover',
    // We need this calculation in order to make sure we have the 30px between
    maxWidth: `calc(50vw - ${isMobile ? '7.5px' : '15px'})`,
    height: '-webkit-fill-available',
    mixBlendMode
  }
  const imageStyleFullMobile: React.CSSProperties = {
    objectFit: 'cover',
    maxWidth: '100vw',
    height: '-webkit-fill-available',
    mixBlendMode
  }
  let marginValue = '0'
  if (component.margin) {
    marginValue = isMobile ? '15px 0' : '30px 0'
  }


  switch (component.type) {
    case ComponentType.IMAGE_FULL:
      const imageComponent = (
        <Box key={`image-full-${index}`} sx={{ 
          margin: marginValue,
          display: 'flex'
        }}>
          <img style={{ width: '100vw', mixBlendMode }} src={`/images/${isMobile ? component.imageMobile : component.image}`} />
        </Box>
      )
      if (component.link) {
        return (<Link key={`image-full-link-${index}`} target="_blank" href={component.link}>{imageComponent}</Link>)
      }
      return imageComponent
    case ComponentType.IMAGE_LEFT_RIGHT:
      if (isMobile) {
        return (
          <React.Fragment key={`image-left-right-${index}`}>
            {renderProjectComponent({
              type: ComponentType.IMAGE_FULL,
              image: component.imageLeft,
              imageMobile: component.imageLeft,
              margin: true,
              mixBlendMode: component.mixBlendMode
            }, isMobile, `left-${index}`)}
            {renderProjectComponent({
              type: ComponentType.IMAGE_FULL,
              image: component.imageRight,
              imageMobile: component.imageRight,
              margin: false,
              mixBlendMode: component.mixBlendMode
            }, isMobile, `right-${index}`)}
          </React.Fragment>
        )
      }
      return (
        <Grid key={`image-left-right-${index}`} container sx={{ 
          display: 'grid',
          gridTemplateColumns: 'auto auto',
          columnGap: isMobile ? '15px' : '30px',
          margin: marginValue,
        }}>
          <Grid item>
            <img style={imageStyle} src={`/images/${component.imageLeft}`} />
          </Grid>
          <Grid item>
            <img style={imageStyle} src={`/images/${component.imageRight}`} />
          </Grid>
        </Grid>
      )
    case ComponentType.NARROW_IMAGE:
      if (isMobile) {
        return renderProjectComponent({
          type: ComponentType.IMAGE_FULL,
          image: component.image,
          imageMobile: component.image,
          margin: component.margin,
          mixBlendMode: component.mixBlendMode
        }, isMobile, index)
      }

      return (
        <Box key={`image-narrow-${index}`} sx={{ 
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: marginValue
        }}>
          <Grid mx="30px">
            <img style={imageStyle} src={`/images/${component.image}`} />
          </Grid>
        </Box>
      )
    case ComponentType.NARROW_TEXT:
      return (
        <Box key={`text-narrow-${index}`} sx={{ 
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          margin: marginValue
        }}>
          {component.title && (
            <Typography mt="30px" variant="h5">{component.title}</Typography>
          )}
          {component.text.map((paragrapth, paragraphIndex) => (
            <Typography key={`text-line-${paragraphIndex}-${index}`} m="30px" textAlign={component.textAlign ?? "justify"} sx={{ maxWidth: '500px'}} style={{whiteSpace: 'pre-line'}}>
              {paragrapth}
            </Typography>
          ))}
        </Box>
      )
    case ComponentType.NARROW_TEXT_IMAGE_LEFT:
      return (
        <Grid key={`image-left-text-${index}`} container sx={isMobile ? {
          flexDirection: 'column',
          margin: marginValue
        } : { 
          display: 'grid',
          gridTemplateColumns: 'auto auto',
          columnGap: '30px',
          margin: marginValue
        }}>
          <Grid item>
            <img style={isMobile ? imageStyleFullMobile : imageStyle} src={`/images/${component.image}`} />
          </Grid>
          <Grid item sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'flex-start',
            margin: marginValue
          }}>
            {component.text.map((paragraph, paragraphIndex) => (
              <Typography key={`text-line-${paragraphIndex}-${index}`} textAlign={component.textAlign ?? "justify"} component="div" m="30px" style={{whiteSpace: 'pre-line'}}>
                {paragraph}
              </Typography>
            ))}
          </Grid>
        </Grid>
      )
    case ComponentType.NARROW_TEXT_IMAGE_RIGHT:
      return (
        <Grid key={`image-right-text-${index}`} container sx={isMobile ? {
          flexDirection: 'column',
          margin: marginValue
        } : { 
          display: 'grid',
          gridTemplateColumns: 'auto auto',
          columnGap: '30px',
          margin: marginValue
        }}>
          <Grid item sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'flex-start'
          }}>
            {component.text.map((paragraph, paragraphIndex) => (
              <Typography key={`text-line-${paragraphIndex}-${index}`} component="div" textAlign={component.textAlign ?? "justify"} m="30px" style={{whiteSpace: 'pre-line'}}>
                {paragraph}
              </Typography>
            ))}
          </Grid>
          <Grid item>
            <img style={isMobile ? imageStyleFullMobile : imageStyle} src={`/images/${component.image}`} />
          </Grid>
        </Grid>
      )
      case ComponentType.IMAGE_CAROUSEL:
        // For mobile we are going to display images individually
        if (isMobile) {
          return (
            <React.Fragment key={`carousel-container-${index}`}>
              {component.images.map((image, imageIndex) => renderProjectComponent({
                type: ComponentType.IMAGE_FULL,
                image: image,
                imageMobile: image,
                margin: (imageIndex % 2) === 0
              }, true, imageIndex))}
            </React.Fragment>
          )
        }

        const carouselContainerStyle = {
          margin: marginValue,
        }
       return (
        <Box key={`carousel-${index}`} sx={carouselContainerStyle}>
          <Carousel images={component.images} />
        </Box>
       )

    default:
      return <></>
  }
}
const ProjectDetailsComponent: React.FunctionComponent<ProjectProps> = ({
  data
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  
  // Workaround for hydration issue caused by react-snap
  // where the page would display as mobile on web on the first load.
  const [hasMounted, setHasMounted] = React.useState(false);
  
  React.useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null
  }

  return (
    <Grid>
      <Box>
        {data.components.map((component, index) => renderProjectComponent(component, isMobile, index))}
      </Box>
    </Grid>
  );
};

export default ProjectDetailsComponent