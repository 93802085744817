import React from 'react';

import { Grid, Theme, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import { ProjectData } from '../data';
import { Project, SlideDirection } from './Project';

interface ProjectListProps {
  data: ProjectData[]
}

const getStyles = (theme: Theme) => ({
  root: {
    display: 'flex', 
    justifyContent: 'space-around', 
    alignItems: 'center', 
    height: '100%', 
    minHeight: '90vh'
  },
});

export const ProjectList: React.FunctionComponent<ProjectListProps> = ({
  data
}) => {
  const theme = useTheme()
  const classes = getStyles(theme)
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const mobileDirections: SlideDirection[] = ["left", "right"]
  const webDirections: SlideDirection[] = ["left", "down", "right"]

  // Keep taking the first value and pushing it at the end
  // Since on web we have 3 columns and on mobile 1-2
  const getDirection = (directions: SlideDirection[]): SlideDirection => {
    const direction = directions.shift()
    if (!direction) {
      return "left"
    }
    directions.push(direction)
    
    return direction
  }
  return (
    <Grid container sx={classes.root} my={2} spacing={2}>
      {data.map((project, index) => (
        <Project 
          key={`project-${index}`}
          data={project} 
          direction={getDirection(isMobile ? mobileDirections : webDirections)} 
        />
      ))}
    </Grid>
  );
};
