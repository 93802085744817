import React from 'react';
import { AttentionSeeker } from 'react-awesome-reveal';
import { Helmet } from 'react-helmet-async';
import { useHistory, useLocation } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, keyframes, Theme, useTheme } from '@mui/material';

import { ReactComponent as CruceIcon } from '../assets/cruce.svg';
import { FooterMini } from '../Components/FooterMini';
import data from '../data';
import { SiteConfig } from '../theme';
import { CollaboratorList } from '../Components/CollaboratorList';

const closeEffect = keyframes`
  from: {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-405deg);
  }
`;

const getStyles = (theme: Theme) => ({
    closeButton: {
        position: 'fixed',
        zIndex: 2,
        float: 'right',
        height: "40px",
        width: "40px",
        right: "20px",
        top: "20px",
        [theme.breakpoints.down("md")]: {
            right: "10px",
            top: "10px",
        },
        // Used for contrast between cover and icon
        mixBlendMode: 'difference',
        filter: 'invert(1)',
        animation: `${closeEffect} ease-out 750ms 3 forwards`,
    },
    backButton: {
        position: 'fixed',
        zIndex: 2,
        float: 'right',
        height: "70px",
        width: "70px",
        right: "20px",
        top: "20px",
        [theme.breakpoints.down("md")]: {
            right: "10px",
            top: "10px",
            width: '50px',
            height: '50px'
        },
        // Used for contrast between cover and icon
        mixBlendMode: 'difference',
        filter: 'invert(1)',
        ['& svg']: {
            width: '100%',
            height: '100%'
        }
    }
  });

export const TeamPage: React.FunctionComponent<{}> = () => {
    const location = useLocation<{ prevPath: string }>()
    const prevPath = location.state?.prevPath || ''
    const theme = useTheme()
    let history = useHistory();
    const classes = getStyles(theme)

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const metaTitle = `${SiteConfig.title} - Team`
    const metaUrl = `${SiteConfig.domainUrl}${location.pathname}`
    const metaImage = `${SiteConfig.domainUrl}/images/logo.svg}`

    return (
        <>
            <Helmet>
                <meta charSet={SiteConfig.charSet} />
                <title>{metaTitle}</title>
                <link rel="canonical" href={metaUrl} />
                <meta property="og:title" content={metaTitle} />
                <meta property="og:url" content={metaUrl} />
                <meta property="og:image" content={metaImage} />
                <meta property="og:type" content="article" />
                <meta property="og:description" content="meet punctoranj team" />
            </Helmet>
            {prevPath && (
                <Box sx={classes.closeButton} onClick={() => history.goBack()}>
                    <CruceIcon
                    fill={theme.palette.primary.contrastText}
                    />
                </Box>
            )}
            {!prevPath && (
                <Box sx={classes.backButton} onClick={() => history.push(`/`)}>
                     <AttentionSeeker delay={300}>
                        <ArrowBackIcon />
                     </AttentionSeeker>
                </Box>
            )}

            <CollaboratorList teamData={data.teamData} />
            <FooterMini />
        </>
    )
}
