import React from 'react';

import { Box, Grid, Typography } from '@mui/material';

import { Colors, SiteConfig } from '../theme';

interface FooterMiniProps {}

const getStyles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '25px 0'
  },
  footerLogo: {
    backgroundColor: Colors.ORANGE,
    height: '20px',
    width: '20px',
    margin: '10px'
  }
});

export const FooterMini: React.FunctionComponent<FooterMiniProps> = () => {
  const classes = getStyles();
  // Workaround for hydration issue caused by react-snap
  // where the page would display as mobile on web on the first load.
  const [hasMounted, setHasMounted] = React.useState(false);
  
  React.useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  }

  return (
    <>
      <Grid container sx={classes.root}>
        <Grid item>
          <Box sx={classes.footerLogo} />
        </Grid>
        <Grid item>
            <Typography>{SiteConfig.copyright}</Typography>
        </Grid>
      </Grid>
    </>
  );
};
