import React from 'react';

import { Box, Grid, Theme, Typography, useTheme } from '@mui/material';

import { CollaboratorAsset, CollaboratorData } from '../data';
import { Colors } from '../theme';
import { Slide } from 'react-awesome-reveal';


interface CollaboratorListProps {
  teamData: CollaboratorData[]
}

const getAssetStyle = (type: CollaboratorAsset, theme: Theme) => {
  const defaultStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'center',
    alignItems: 'center'
  }
  switch (type) {
    case CollaboratorAsset.BIG_CIRCLE:
      return {
        ...defaultStyle,
        marginTop: '100px',
        alignSelf: 'start',
        [theme.breakpoints.down("sm")]: {
          margin: '30px 0 10px 0'
        },
        ['& .geometical']: {
          marginBottom: '10px',
          height: '500px',
          width: '500px',
          backgroundColor: Colors.ORANGE,
          borderRadius: '50%',
          [theme.breakpoints.down("sm")]: {
            height: '300px',
            width: '300px',
          }
        }
      }
    case CollaboratorAsset.MEDIUM_CIRCLE:
      return {
        ...defaultStyle,
        alignSelf: 'center',
        [theme.breakpoints.down("sm")]: {
          marginTop: '10px 0 0 10px'
        },
        ['& .geometical']: {
          marginBottom: '10px',
          height: '500px',
          width: '500px',
          backgroundColor: Colors.ORANGE,
          borderRadius: '50%',
          [theme.breakpoints.down("sm")]: {
            height: '300px',
            width: '300px',
          }
        }
      }
    case CollaboratorAsset.SMALL_CIRCLE:
      return {
        ...defaultStyle,
        alignSelf: 'end',
        marginBottom: '100px',
        [theme.breakpoints.down("sm")]: {
          margin: '10px 0'
        },
        ['& .geometical']: {
          marginBottom: '10px',
          height: '170px',
          width: '170px',
          backgroundColor: Colors.ORANGE,
          borderRadius: '50%',
          [theme.breakpoints.down("sm")]: {
            height: '130px',
            width: '130px',
          }
        }
      }
    case CollaboratorAsset.SMALL_SQUARE:
    default:
      return {
        ...defaultStyle,
        alignSelf: 'end',
        [theme.breakpoints.down("sm")]: {
          margin: '10px 0'
        },
        ['& .geometical']: {
          marginBottom: '10px',
          height: '150px',
          width: '150px',
          backgroundColor: Colors.ORANGE,
          [theme.breakpoints.down("sm")]: {
            height: '110px',
            width: '110px',
          }
        }
      }
  }
}

export const CollaboratorList: React.FunctionComponent<CollaboratorListProps> = ({
  teamData
}) => {
  const theme = useTheme();

  return (
    <>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-around"
          sx={{ minHeight: '90vh'}}
        >
          {teamData.map((member, index) => (
            <Grid key={`collab-${index}`} item sx={getAssetStyle(member.asset, theme)}>
              <Slide delay={150 * index}key={member.name} direction="down">
                <Box className="geometical"></Box>
                <Typography>{member.name}</Typography>
                <Typography>- {member.position} -</Typography>
              </Slide>
            </Grid>
          ))}
        </Grid>
    </>
  );
};
